import React, { FC } from 'react';

import UserCard from '../UserCard/UserCard';
import SpaceBetweenBox from '../SpaceBetweenBox/SpaceBetweenBox';
import { Typography } from '../Typography/Typography';

export interface HomeHeaderProps {
  userName?: string;
  picture?: string | null;
  showUserType?: boolean;
  downloadPicture?: (picture: string) => Promise<string | undefined>;
  handleNavigate: () => void;
}

const HomeHeader: FC<HomeHeaderProps> = ({
  userName,
  picture,
  showUserType = true,
  downloadPicture,
  handleNavigate,
}) => {
  return (
    <SpaceBetweenBox
      sx={{
        marginBottom: '56px',
      }}
    >
      <Typography variant="h3">Hi {userName?.split(' ')[0]}!</Typography>
      <SpaceBetweenBox>
        <UserCard
          name={userName}
          picture={picture}
          showUserType={showUserType}
          downloadPicture={downloadPicture}
          handleClick={handleNavigate}
        />
      </SpaceBetweenBox>
    </SpaceBetweenBox>
  );
};

export default HomeHeader;
