import * as Yup from 'yup';
import {
  endOfToday,
  isAfter,
  isBefore,
  isValid,
  parse,
  startOfToday,
  startOfDay,
  isPast,
  isSameDay,
} from 'date-fns';

import {
  DateFormat,
  getDateFormat,
  verifyFutureTime,
} from './dateAndTimeUtils';
import { ActivityFrequency } from './activityUtils';

export const requiredMessage = 'This field cannot be left blank.';

export const validDateMessage = 'Please type a valid date format.';

export const validEmailMessage = 'Please type a valid email format.';

export const matchPasswordMessage = 'Password fields should match.';

export const incorrectPasswordMessage = 'Incorrect password format.';

export const validPhoneMessage = 'Please type a valid phone number.';

export const validZipMessage = 'Zip code must be exactly 5 numbers';

export const validDecimalMessage = 'Please type a valid decimal value';

const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const addPhoneNumberValidation = (errorMessage = validPhoneMessage) =>
  Yup.string()
    .trim()
    .test('valid-phone-number', errorMessage, (value) => {
      if (!value) return true;
      return phoneRegExp.test(value) || value.length === 12;
    });

export const addDateValidation = (
  format: DateFormat = 'monthDayYear',
  errorMessage: string = validDateMessage,
  shouldParse: boolean = true,
  isNullable: boolean = false
) =>
  Yup.date()
    .nullable(isNullable as any)
    .transform((_value, originalValue) => {
      if (shouldParse) {
        return parse(originalValue, getDateFormat(format), startOfToday());
      }

      // Adding new Date to convert ISO string to Date object
      return new Date(originalValue);
    })
    .typeError(errorMessage);

export const getZipValidation = (errorMessage = validZipMessage) =>
  Yup.string()
    .trim()
    .matches(/^[0-9]+$/, errorMessage)
    .test('len', errorMessage, (val) => val?.length === 5);

export const addMinDateValidation = (minDate?: Date, errorMessage?: string) =>
  Yup.date()
    .nullable()
    .test('is-valid', validDateMessage, (date) => {
      if (!date) return true;

      return isValid(date);
    })
    .test('min-date', errorMessage || 'The date is not available', (date) => {
      if (!date || !minDate) {
        return true;
      }

      return !isBefore(startOfDay(date), startOfDay(new Date(minDate)));
    })
    .typeError(validDateMessage);

export const addNotInPastDateValidation = (
  errorMessage?: string,
  shouldParse: boolean = true
) =>
  Yup.date()
    .nullable()
    .transform((_value, originalValue) => {
      if (shouldParse) {
        return parse(originalValue, 'MM/dd/yyyy', startOfToday());
      }
      return originalValue;
    })
    .test('not-in-past', 'Date must not be in the past', (value) => {
      if (!value) {
        value = new Date();
      }

      return (
        isValid(value) &&
        (isSameDay(value, endOfToday()) || !isBefore(value, startOfToday()))
      );
    })
    .typeError(errorMessage || validDateMessage);

export const addNotInFutureDateValidation = () =>
  Yup.date()
    .nullable()
    .test('birthdate', 'Date must not be in the future', (date) => {
      if (!date) {
        return true;
      }

      return isPast(startOfDay(date));
    })
    .typeError(validDateMessage);

export const addTimeValidation = (
  errorMessage: string = 'Please insert a valid time'
) => {
  return Yup.string()
    .matches(/\d{2}:\d{2}/, errorMessage)
    .test('is-valid-time', errorMessage, (value = '') => {
      const time = parse(value, 'hh:mm', startOfToday());
      const [hour, minutes] = value.split(':');

      return (
        isValid(time) &&
        parseInt(hour, 10) > 0 &&
        parseInt(hour, 10) <= 12 &&
        parseInt(minutes, 10) <= 59
      );
    })
    .typeError(errorMessage);
};

export const addTimeInTheFutureValidation = (
  errors: { future: string; valid: string } = {
    future: 'Entry cannot be a time in the future',
    valid: 'Please insert a valid time',
  }
) => {
  return addTimeValidation(errors.valid).when(['dayPeriod'], {
    is: (dayPeriod: string) => dayPeriod === 'am',
    then: Yup.string().test('is-past-time', errors.future, (value = '') =>
      verifyFutureTime(value)
    ),
    otherwise: Yup.string().test(
      'is-past-time',
      errors.future,
      (value = '') => {
        return verifyFutureTime(value, true);
      }
    ),
  });
};

export const addDayPeriodValidation = () => Yup.string().oneOf(['am', 'pm']);

export const addWeekDaysValidation = () =>
  Yup.array().when('frequency', {
    is: (frequency: ActivityFrequency) =>
      frequency === ActivityFrequency.Weekly ||
      frequency === ActivityFrequency.Biweekly ||
      frequency === ActivityFrequency.Triweekly,
    then: Yup.array().of(Yup.string()).min(1),
  });

export const addEndsAtValidation = (
  errors: {
    future: string;
    afterStart: string;
  } = {
    future: 'End date must be in the future',
    afterStart: 'End date must be after start date',
  },
  shouldParse: boolean = false
) =>
  Yup.date().when(
    ['ends', 'date'],
    (ends = 'never', starts_at = new Date()) => {
      return ends === 'never'
        ? Yup.date()
            .nullable()
            .transform((curr, orig) => (orig === '' ? null : curr))
        : Yup.date()
            .transform((_value, originalValue) => {
              if (shouldParse) {
                return parse(originalValue, 'MM/dd/yyyy', startOfToday());
              }
              return originalValue;
            })
            .typeError(validDateMessage)
            .test('is-future', errors.future, (value = new Date()) => {
              return isValid(value) && isAfter(value, endOfToday());
            })
            .test('is-after-start', errors.afterStart, (value = new Date()) => {
              return isValid(value) && isAfter(value, starts_at);
            });
    }
  );

export const getAddressValidation = (
  errors: {
    required: string;
    zip: string;
  } = {
    required: requiredMessage,
    zip: validZipMessage,
  }
) =>
  Yup.object().shape({
    address: Yup.string().trim().required(errors.required),
    zip: getZipValidation(errors.zip).required(errors.required),
    city: Yup.string().trim().required(errors.required),
    state: Yup.string().trim().required(errors.required),
  });

export const getBhbValidation = (
  errors: {
    future: string;
    valid: string;
    required: string;
    bhb: string;
  } = {
    future: 'Entry cannot be a time in the future',
    valid: 'Please insert a valid time',
    required: requiredMessage,
    bhb: 'Your BHB value needs to be higher than 0',
  }
) =>
  Yup.object().shape({
    time: addTimeInTheFutureValidation({
      future: errors.future,
      valid: errors.valid,
    }).required(errors.required),
    value: Yup.string().not(['0.0'], errors.bhb).required(errors.required),
  });

export const verifyDecimal = (
  min: number,
  max: number,
  errorMessage = validDecimalMessage
) =>
  Yup.string()
    .trim()
    .test('is-valid-decimal', errorMessage, (value = '') => {
      if (!value) return true;
      const number = parseFloat(value);
      return !!(number && number >= min && number <= max);
    });

export const decimalValidation = (
  min: number,
  max: number,
  errorMessage = validDecimalMessage,
  isRequired?: boolean
) => {
  const validation = verifyDecimal(min, max, errorMessage);

  return Yup.object().shape({
    decimal: isRequired ? validation.required(requiredMessage) : validation,
  });
};

export const subjectIdValidation = Yup.string()
  .trim()
  .test('len', 'Please type a valid 3 digit ID', (val) => {
    if (!val) {
      return true;
    }

    return val.length === 3;
  });

export const weightValidation = verifyDecimal(
  1,
  999.9,
  'Please type a value between 1 and 999.9'
);

export const heightValidation = verifyDecimal(
  100,
  240,
  'Please type a value between 100 and 240'
);

export const emailValidation = Yup.string().trim().email(validEmailMessage);

export const confirmEmailValidation = emailValidation.oneOf(
  [Yup.ref('email'), null],
  'Emails should match'
);
