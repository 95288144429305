import { Loader } from '@fdha/web-ui-library';
import { Box, Chip, Divider, Typography, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import React, { useMemo } from 'react';
import { useGetMenuQuery, useListDishesQuery } from '@fdha/graphql-api-foodops';

import { BasePage, PreviewPaper } from '../../../../components';
import { getOptions } from '../../../../utils';

const NUMBER_OF_RESULTS = 10000;

const MenuPreview = () => {
  const theme = useTheme();
  const { menuId } = useParams();

  const { data, loading } = useGetMenuQuery({
    variables: { id: menuId || '' },
    fetchPolicy: 'cache-and-network',
  });

  const menu = data?.menuById;

  const { data: dishesData, loading: loadingDishes } = useListDishesQuery({
    variables: { first: NUMBER_OF_RESULTS },
    fetchPolicy: 'cache-and-network',
  });

  const dishOptions = useMemo(
    () => getOptions(dishesData?.dishes.edges),
    [dishesData?.dishes.edges]
  );

  const values = [
    {
      meal: 'Breakfast',
      dishes:
        dishOptions.filter((opt) => menu?.breakfast.includes(opt.id)) || [],
    },
    {
      meal: 'Lunch',
      dishes: dishOptions.filter((opt) => menu?.lunch.includes(opt.id)) || [],
    },
    {
      meal: 'Dinner',
      dishes: dishOptions.filter((opt) => menu?.dinner.includes(opt.id)) || [],
    },
    {
      meal: 'Snacks',
      dishes: dishOptions.filter((opt) => menu?.snacks.includes(opt.id)) || [],
    },
    {
      meal: 'Sides',
      dishes: dishOptions.filter((opt) => menu?.sides.includes(opt.id)) || [],
    },
  ];

  const renderDishOption = (dish: string, id: string) => (
    <Chip
      key={id}
      label={dish}
      sx={{
        backgroundColor: theme.palette.grey[300],
      }}
    />
  );

  return (
    <BasePage>
      <BasePage.BackButton to="/create" />
      {loading || loadingDishes ? (
        <Loader />
      ) : (
        <>
          <PreviewPaper>
            <Typography padding={2} fontWeight="500">
              Menu Name
            </Typography>
            <Divider />
            <Typography padding={2}>{menu?.name}</Typography>
          </PreviewPaper>
          {values.map((value) => (
            <PreviewPaper key={value.meal} sx={{ padding: 2 }}>
              <Typography variant="h6">{value.meal}</Typography>
              <Divider sx={{ mt: 1, mb: 2 }} />
              <Box display="flex" flexWrap="wrap" columnGap={1} rowGap={1}>
                {value.dishes.length ? (
                  value.dishes.map((dish) =>
                    renderDishOption(dish.label, dish.id)
                  )
                ) : (
                  <Typography
                    variant="subtitle2"
                    fontStyle="italic"
                    color={theme.palette.text.secondary}
                  >
                    No options selected for {value.meal.toLowerCase()}.
                  </Typography>
                )}
              </Box>
            </PreviewPaper>
          ))}
        </>
      )}
    </BasePage>
  );
};

export default MenuPreview;
