import {
  Box,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableContainer,
  Table as MuiTable,
  TableBody,
  TablePagination,
} from '@mui/material';
import React, { ReactNode } from 'react';

import { CircularProgress, Typography } from '..';
import { KeyOfNestedLeaves } from '../../types/keyOfNestedLeaves';

type Order = 'asc' | 'desc';
export const INITIAL_ROWS_PER_PAGE = 10;
const ROWS_PER_PAGE = [5, 10, 25, 50, 100] as const;
export type RowsPerPageOptions = (typeof ROWS_PER_PAGE)[number];

export interface HeadCellBase {
  label: string;
  sortable?: boolean;
  searchable?: boolean;
}

export interface HeadCell<D extends object> extends HeadCellBase {
  id: KeyOfNestedLeaves<D>;
}

export interface FlatHeadCell extends HeadCellBase {
  id: string;
}

export interface TableProps<D extends object> {
  actions?: 'left' | 'right' | 'both';
  headCells: HeadCell<D>[];
  initialOrderBy?: KeyOfNestedLeaves<D>;
  onPageChange?: (page: number, shouldLoadMore: boolean) => void;
  page?: number;
  rowsPerPage?: RowsPerPageOptions;
  onRowsPerPageChange?: (rowsPerPage: RowsPerPageOptions) => void;
  emptyState?: string;
  renderRow: (row: D, index: number) => React.ReactNode;
  rows: D[];
  isLoading?: boolean;
  totalRowCount?: number;
  withPagination?: boolean;
  onSortChange?: (field: KeyOfNestedLeaves<D>, order: Order) => void;
}

export function Table<D extends object>({
  actions,
  headCells,
  initialOrderBy = headCells[0]?.id,
  onPageChange,
  page = 0,
  rowsPerPage = INITIAL_ROWS_PER_PAGE,
  onRowsPerPageChange,
  emptyState,
  renderRow,
  rows,
  isLoading,
  totalRowCount,
  withPagination,
  onSortChange,
}: TableProps<D>) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] =
    React.useState<KeyOfNestedLeaves<D>>(initialOrderBy);

  const shouldLoadMoreData = (newPage: number) => {
    if (totalRowCount != null && rows.length >= totalRowCount) {
      return false;
    }

    const amountOfDataToShow = rowsPerPage * (newPage + 1);
    return rows.length < amountOfDataToShow;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    const shouldLoadMore = shouldLoadMoreData(newPage);
    onPageChange && onPageChange(newPage, shouldLoadMore);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const rowsPerPage = parseInt(event.target.value, 10) as RowsPerPageOptions;
    onRowsPerPageChange && onRowsPerPageChange(rowsPerPage);
  };

  const createSortHandler = (property: KeyOfNestedLeaves<D>) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    onSortChange?.(property, isAsc ? 'desc' : 'asc');
  };

  const renderTableHead = () => (
    <TableHead>
      <TableRow>
        {(actions === 'left' || actions === 'both') && (
          <TableCell padding="checkbox" />
        )}
        {headCells.map<ReactNode>((headCell, index) => (
          <TableCell
            key={typeof headCell.id === 'string' ? headCell.id : index}
            data-testid={`TABLE_CELL_${headCell.id.toString().toUpperCase()}`}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                data-testid={
                  orderBy === headCell.id
                    ? `TABLE_SORT_${order.toUpperCase()}`
                    : 'TABLE_SORT_ASC'
                }
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        {(actions === 'right' || actions === 'both') && (
          <TableCell padding="checkbox" />
        )}
      </TableRow>
    </TableHead>
  );

  const renderLoading = () => (
    <TableRow>
      {(actions === 'left' || actions === 'both') && (
        <TableCell padding="checkbox" />
      )}
      <TableCell colSpan={headCells.length}>
        <Box display="flex" justifyContent="center">
          <CircularProgress color="primary" variant="indeterminate" />
        </Box>
      </TableCell>
      {(actions === 'right' || actions === 'both') && (
        <TableCell padding="checkbox" />
      )}
    </TableRow>
  );

  const rowsToRender =
    withPagination && rows
      ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : rows;

  const renderEmptyState = () => {
    if (emptyState && rowsToRender.length === 0) {
      const size =
        actions === 'both'
          ? headCells.length + 2
          : actions === 'left' || actions === 'right'
          ? headCells.length + 1
          : headCells.length;
      return (
        <TableRow>
          <TableCell colSpan={size}>
            <Box display="flex" justifyContent="center">
              <Typography color="text.secondary" variant="h6">
                {emptyState}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      );
    }
    return null;
  };

  const EmptyState = renderEmptyState();

  const renderTableBody = () => {
    return (
      <TableBody>
        {isLoading
          ? renderLoading()
          : EmptyState || rowsToRender.map(renderRow)}
      </TableBody>
    );
  };

  const renderTablePagination = () => (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      component="div"
      count={totalRowCount != null ? totalRowCount : -1}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      data-testid="TABLE_PAGINATION"
    />
  );

  return (
    <>
      <TableContainer>
        <MuiTable>
          {renderTableHead()}
          {renderTableBody()}
        </MuiTable>
      </TableContainer>
      {withPagination && !EmptyState && !isLoading && renderTablePagination()}
    </>
  );
}
