import { Box, styled, Paper as MuiPaper } from '@mui/material';
import React, { FC, useState } from 'react';

import { Typography, UploadPicture } from '..';

import EditModeCard from './EditModeCard';
import ViewModeCard from './ViewModeCard';

export interface UserData {
  name?: string;
  email?: string;
  createdAt?: string;
  phoneNumber?: string;
  picture?: string | null;
}

export interface ProfileCardProps {
  user: UserData;
  handleUpload: (imageFile: File) => void;
  downloadPicture?: (picture: string) => Promise<string | undefined>;
  handleUpdateData: (data: {
    name?: string;
    email?: string;
    phone_number?: string;
  }) => Promise<void>;
  editable?: boolean;
}

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: '16px 41px',
  border: 'none',
  boxShadow: theme.shadows[2],
  minWidth: '500px',
  marginBottom: theme.spacing(3),
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingBottom: theme.spacing(2),
}));

const ProfileCard: FC<ProfileCardProps> = ({
  user,
  handleUpload,
  downloadPicture,
  handleUpdateData,
  editable = false,
}) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <Paper data-testid="PROFILE_CARD">
      <Header>
        <UploadPicture
          name={user?.name}
          picture={user?.picture}
          downloadPicture={downloadPicture}
          handleUpload={handleUpload}
          dataTestId="PROFILE_AVATAR"
        />
        <Typography
          variant="h5"
          sx={{ paddingLeft: 2 }}
          data-testid="PROFILE_AVATAR_NAME"
        >
          {user?.name}
        </Typography>
      </Header>
      {editable && editMode ? (
        <EditModeCard
          user={user}
          handleEditMode={setEditMode}
          handleUpdateData={handleUpdateData}
        />
      ) : (
        <ViewModeCard
          user={user}
          handleEditMode={setEditMode}
          editable={editable}
        />
      )}
    </Paper>
  );
};

export default ProfileCard;
