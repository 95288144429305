import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { signOut } from '@aws-amplify/auth';
import {
  Accordion,
  CardButton,
  Loader,
  ProfileCard,
  UserData,
} from '@fdha/web-ui-library';
import { displayProtectedImage, ProfileChangePassword } from '@fdha/web-auth';
import {
  MeDocument,
  useMeQuery,
  useUpdateProfileMutation,
  useUploadProfilePictureMutation,
} from '@fdha/graphql-api-foodops';

import BasePage from '../../components/BasePage/BasePage';

export const Profile = () => {
  const navigate = useNavigate();

  const { data, loading } = useMeQuery();
  const [uploadPicture] = useUploadProfilePictureMutation();
  const [updateProfile] = useUpdateProfileMutation();

  const [userData, setUserData] = useState<UserData>({
    name: data?.me?.name,
    email: data?.me?.email,
    createdAt: data?.me?.created_at,
    phoneNumber: data?.me?.phone_number,
    picture: data?.me?.picture,
  });

  useEffect(() => {
    setUserData({
      name: data?.me?.name,
      email: data?.me?.email,
      createdAt: data?.me?.created_at,
      phoneNumber: data?.me?.phone_number,
      picture: data?.me?.picture,
    });
  }, [data]);

  if (loading) {
    return <Loader />;
  }

  const handleEditProfile = async (profile: {
    name?: string;
    email?: string;
    phone_number?: string;
  }) => {
    setUserData({
      ...userData,
      name: profile.name,
      email: profile.email,
      phoneNumber: profile.phone_number,
    });
    await updateProfile({
      variables: {
        props: {
          email: profile.email,
          phone_number: profile.phone_number,
          name: profile.name,
        },
      },
      refetchQueries: [MeDocument],
    });
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('login', { replace: true });
    } catch (error) {
      console.error('error signing out: ', error);
    }
  };

  const handleUpload = async (imageFile: File) => {
    await uploadPicture({
      variables: {
        props: imageFile,
      },
    });
  };

  return (
    <BasePage>
      <BasePage.BackButton to="/" />
      <Typography variant="h5" sx={{ marginBottom: 3 }}>
        Your profile
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="stretch"
        flex="1 0 auto"
        flexWrap="nowrap"
        data-testid="FOODOPS_CONTAINER"
      >
        <Box flex={1}>
          <ProfileCard
            handleUpload={handleUpload}
            downloadPicture={displayProtectedImage}
            user={userData}
            handleUpdateData={handleEditProfile}
            editable
          />
        </Box>
        <Box flex={2} marginLeft={3}>
          <Accordion title="Change password" sx={{ padding: 1 }}>
            <ProfileChangePassword email={data?.me.email} />
          </Accordion>
          <CardButton
            label="Logout"
            handleClick={handleSignOut}
            icon="log-out-outline"
          />
        </Box>
      </Box>
    </BasePage>
  );
};
