import { DeliveryShipmentStatus as TrackingStatus } from '@fdha/common-utils';

export enum DeliveryStatusEnum {
  Closed = 'closed',
  Open = 'open',
}

export type ShipmentLabel = {
  trackingStatus: TrackingStatus;
};
