import React, { useState } from 'react';
import { SelectChangeEvent, SelectProps } from '@mui/material';

import { Select } from '..';
import { useSnackbar } from '../..';

export enum UserType {
  Patient = 'patient',
  Coach = 'coach',
  Foodops = 'foodops',
  ClinicalTrialPatient = 'clinical_trial_patient',
  Caretaker = 'caretaker',
  CustomerService = 'customer_service',
  SiteStaff = 'site_staff',
}

type SelectTypeProps = {
  userId: string;
  userType: UserType;
  handleUpdateUserType?: (userId: string, newType: UserType) => void;
} & Omit<
  SelectProps,
  'name' | 'onChange' | 'value' | 'options' | 'showEmptyValue'
>;

const convertStringToUserType = (s: string): UserType => {
  switch (s) {
    case UserType.ClinicalTrialPatient:
      return UserType.ClinicalTrialPatient;
    case UserType.Caretaker:
      return UserType.Caretaker;
    default:
      return UserType.Patient;
  }
};

const SelectType: React.FC<SelectTypeProps> = (props) => {
  const { showSnackbar } = useSnackbar();

  const { userId, userType, handleUpdateUserType, ...muiProps } = props;

  const options = [
    { label: 'CT Patient', value: UserType.ClinicalTrialPatient },
    { label: 'DH Patient', value: UserType.Patient },
    { label: 'Caretaker', value: UserType.Caretaker },
  ];

  const [selectedType, setSelectedType] = useState(userType);

  const handleType = async (newType: UserType, oldType: UserType) => {
    try {
      setSelectedType(newType);
      handleUpdateUserType && (await handleUpdateUserType(userId, newType));
    } catch {
      setSelectedType(oldType);
      showSnackbar({
        message: `Error to update patient type`,
        severity: 'error',
      });
    }
  };

  return (
    <Select
      name="userType"
      onChange={(event) =>
        handleType(
          convertStringToUserType(
            (event as SelectChangeEvent<string>).target.value
          ),
          userType
        )
      }
      value={selectedType}
      options={options}
      showEmptyValue={false}
      {...muiProps}
    />
  );
};

export default SelectType;
