import React, { FC } from 'react';
import { Grid } from '@mui/material';
import {
  LabelBox,
  getFoodProgramStatus,
  getWeekDays,
} from '@fdha/web-ui-library';
import { FoodProgramV2 } from '@fdha/graphql-api-foodops';
import { getConcatWeekdaysOnDiet } from '@fdha/common-utils';

export interface FoodProgramInformationProps {
  foodProgramUser?: FoodProgramV2 | null;
}

const FoodProgramInformation: FC<FoodProgramInformationProps> = ({
  foodProgramUser,
}) => {
  const firstUserDeliveryMenu = foodProgramUser?.userDeliveryMenus?.[0];
  const weekdaysOnDiet = foodProgramUser?.userDeliveryMenus?.length
    ? getConcatWeekdaysOnDiet(foodProgramUser?.userDeliveryMenus)
    : [];

  return (
    <Grid container columnSpacing="100px" rowSpacing={0}>
      <LabelBox
        size={4}
        label="Status on Trial"
        value={
          foodProgramUser?.status &&
          getFoodProgramStatus[foodProgramUser.status]
        }
      />
      <LabelBox
        size={4}
        label="Diet Plan"
        value={foodProgramUser?.dietPlan.name}
      />
      <LabelBox
        size={4}
        label="Days on Diet"
        value={getWeekDays(weekdaysOnDiet)}
      />
      <LabelBox
        size={4}
        label="How many snacks and sides per day?"
        value={firstUserDeliveryMenu?.snacksPerDay?.toString()}
      />
      <LabelBox
        size={4}
        label="How many sachets per day?"
        value={firstUserDeliveryMenu?.sachetsPerDay?.toString()}
      />
    </Grid>
  );
};

export default FoodProgramInformation;
