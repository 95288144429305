import React from 'react';
import { useNavigate } from 'react-router';

import { BasePage } from '../../../../components';

import AddOrEditDietPlan from './AddOrEditDietPlan';

const AddDietPlan = () => {
  const navigate = useNavigate();
  const parentRoute = '/create/diet-plan';

  return (
    <BasePage>
      <BasePage.BackButton to={parentRoute} />
      <BasePage.Wrapper>
        <BasePage.Title>Create Diet Plan</BasePage.Title>
        <AddOrEditDietPlan
          onCancel={() => navigate(parentRoute)}
          onSuccess={() => navigate(parentRoute)}
        />
      </BasePage.Wrapper>
    </BasePage>
  );
};

export default AddDietPlan;
