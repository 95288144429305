import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  useTheme,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  AccordionProps as MuiAccordionProps,
  Theme,
  SxProps,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import { Typography } from '../Typography/Typography';
import { Icon } from '..';

export interface AccordionProps extends MuiAccordionProps {
  title?: string;
  subtitle?: string;
  collapsedMode?: 'full' | 'partial';
  hasHeaderDivider?: boolean;
  summary?: React.ReactNode;
  summaryStyle?: SxProps<Theme>;
  rightAdornment?: React.ReactNode;
  accordionStyle?: SxProps<Theme>;
  v2?: boolean;
}

const PartialCollapse = ({ ...props }) => {
  const COLLAPSE_PREVIEW_HEIGHT = 265;
  const contentRef = useRef<HTMLDivElement>(null);

  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(
        contentRef.current.clientHeight > COLLAPSE_PREVIEW_HEIGHT
      );
    }
  }, [contentRef]);

  const maxHeight = !props.in ? `${COLLAPSE_PREVIEW_HEIGHT}px` : undefined;
  const overflow = !props.in ? 'hidden' : 'unset';

  return (
    <Box
      maxHeight={maxHeight}
      overflow={overflow}
      sx={{
        mask:
          isOverflowing && !props.in
            ? 'linear-gradient(0deg, transparent 16px, #000000 66px)'
            : undefined,
      }}
    >
      <Box ref={contentRef}>{props.children}</Box>
    </Box>
  );
};

const Accordion: React.FC<AccordionProps> = (props) => {
  const theme = useTheme();

  /************ V2 updates *********************/
  const accordionStyle = props.accordionStyle
    ? props.accordionStyle
    : props.v2
    ? {
        borderRadius: '10px',
        border: `border: 0.5px solid ${grey[700]}`,
        boxShadow: theme.shadows[2],
      }
    : {};
  const iconColor = props.v2
    ? theme.palette.text.secondary
    : theme.palette.primary.main;

  /*********************************************/

  const {
    collapsedMode,
    hasHeaderDivider,
    title,
    subtitle,
    rightAdornment,
    ...muiProps
  } = props;

  const TransitionComponent =
    collapsedMode === 'partial' ? PartialCollapse : undefined;

  return (
    <MuiAccordion
      {...muiProps}
      disableGutters
      TransitionComponent={TransitionComponent}
      sx={accordionStyle}
      square={props.v2}
    >
      <AccordionSummary
        expandIcon={
          <Icon
            name="arrow-ios-downward-outline"
            size="large"
            fill={iconColor}
          />
        }
        sx={props.summaryStyle}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          {props.summary || (
            <Box
              minHeight="52px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography variant="h6" data-testid="ACCORDION_OPTION_TITLE">
                {title}
              </Typography>
              {subtitle && (
                <Typography
                  variant="body2"
                  color={theme.palette.text.secondary}
                  data-testid="ACCORDION_OPTION_SUBTITLE"
                >
                  {subtitle}
                </Typography>
              )}
            </Box>
          )}
          {rightAdornment}
        </Box>
      </AccordionSummary>
      <AccordionDetails
        data-testid="ACCORDION_DETAILS"
        sx={{
          borderTop: hasHeaderDivider
            ? `1px solid ${theme.palette.divider}`
            : undefined,
          wordBreak: 'break-word',
        }}
      >
        {props.children}
      </AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
