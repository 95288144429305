import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { LabelBox, Loader } from '@fdha/web-ui-library';
import { PatientUser } from '@fdha/graphql-api-foodops';
import { useFeatureFlag } from '@fdha/common-hooks';

export interface AccountInformationProps {
  patient?: PatientUser | null;
}

const AccountInformation: FC<AccountInformationProps> = ({ patient }) => {
  const { isFeatureEnabled, isLoading: loadingFeatureFlag } = useFeatureFlag();
  const showSiteFeatures = isFeatureEnabled('show_site_features');

  if (loadingFeatureFlag) {
    return <Loader />;
  }

  return (
    <Grid container columnSpacing="100px" rowSpacing={0}>
      <LabelBox size={4} label="Name" value={patient?.name} />
      <LabelBox size={4} label="Email" value={patient?.email} />
      <LabelBox size={4} label="Phone number" value={patient?.phone_number} />
      <LabelBox size={4} label="Subject ID" value={patient?.subject_id} />
      <LabelBox
        size={4}
        label="Associated Site"
        value={
          showSiteFeatures ? patient?.site?.name : patient?.associated_site
        }
      />
      <LabelBox
        size={4}
        label="Address"
        value={patient?.address?.street_address}
      />
      <LabelBox
        size={4}
        label="Complement"
        value={patient?.address?.complement}
      />
      <LabelBox size={4} label="City" value={patient?.address?.locality} />
      <LabelBox size={4} label="State" value={patient?.address?.region} />
      <LabelBox
        size={4}
        label="Zip/Postal Code"
        value={patient?.address?.postal_code}
      />
    </Grid>
  );
};

export default AccountInformation;
