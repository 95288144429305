import { formatUTCDate, LabelBox } from '@fdha/web-ui-library';
import { Grid } from '@mui/material';
import React from 'react';
import { PlannedPauseV2 } from '@fdha/graphql-api-foodops';

interface PlannedPauseInformationProps {
  pauses: PlannedPauseV2[];
}

const PlannedPauseInformation: React.FC<PlannedPauseInformationProps> = ({
  pauses,
}) => {
  const defaultPauses: PlannedPauseV2[] = [{ startDate: '', endDate: '' }];
  const currentPauses = pauses.length ? pauses : defaultPauses;

  const renderPauseItem = (pause: PlannedPauseV2) => {
    return (
      <>
        <LabelBox
          size={6}
          label="Start Date"
          value={formatUTCDate(pause.startDate)}
        />
        <LabelBox
          size={6}
          label="End Date"
          value={formatUTCDate(pause.endDate || undefined)}
        />
      </>
    );
  };

  return (
    <Grid container columnSpacing={0} rowSpacing={0}>
      {currentPauses.map((pause) => renderPauseItem(pause))}
    </Grid>
  );
};

export default PlannedPauseInformation;
