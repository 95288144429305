import fdhaConfig from '@fdha/aws-config-foodops';

const apiKey = fdhaConfig.api.apiKey;
const configs = {
  method: 'GET',
  headers: {
    'x-api-key': apiKey,
  },
};

export const doGet = async (url: string) => {
  const res = await fetch(url, configs);
  return await res.json();
};
