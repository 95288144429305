import React from 'react';

import { SMSChallengeCode } from '../components';
import LoginBase from '../components/LoginBase';

export const SMSChallengeCodePage = () => {
  return (
    <LoginBase>
      <SMSChallengeCode />
    </LoginBase>
  );
};
