import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import React from 'react';
import { Link, useLocation, Outlet } from 'react-router-dom';

import BasePage from '../../components/BasePage/BasePage';

const tabs = [
  { value: 'Menu', label: 'Menu', to: '/create', href: '/create' },
  {
    value: 'dietPlan',
    label: 'Diet Plan',
    to: 'diet-plan',
    href: '/create/diet-plan',
  },
];

interface CreateComponentProps {
  children?: Partial<ElementCSSInlineStyle>;
}

export const Create: React.FC<CreateComponentProps> = ({ children }) => {
  const location = useLocation();

  const selectedTab =
    tabs.find((tab) => tab.href === location.pathname)?.value || 'Menu';

  return (
    <BasePage data-testid="CREATE_CONTAINER">
      <TabContext value={selectedTab}>
        <Box borderBottom={1} borderColor="divider">
          <TabList orientation="horizontal" value={selectedTab}>
            {tabs.map((tab, index) => (
              <Tab
                data-testid={`CREATE_TAB_${tab.value.toUpperCase()}`}
                key={index}
                label={tab.label}
                value={tab.value}
                component={Link}
                to={tab.to}
              />
            ))}
          </TabList>
        </Box>
        <TabPanel
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            padding: 0,
            paddingTop: 2,
          }}
          value={selectedTab}
        >
          <Box display="flex" flexDirection="column" flex={1}>
            <Outlet />
          </Box>
        </TabPanel>
      </TabContext>
    </BasePage>
  );
};
