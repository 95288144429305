import React, { useEffect } from 'react';
import { NetworkStatus } from '@apollo/client';
import { Chip, Paper, TableCell, TableRow } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import {
  HeadCell,
  HomeHeader,
  Loader,
  SearchableTableHeader,
  Table,
  useTable,
  getFoodProgramStatus,
  getWeekDays,
} from '@fdha/web-ui-library';
import { displayProtectedImage } from '@fdha/web-auth';
import Icon from 'react-eva-icons';
import { grey } from '@mui/material/colors';
import {
  CtPatient,
  useListCtPatientsQuery,
  useMeQuery,
} from '@fdha/graphql-api-foodops';
import { getConcatWeekdaysOnDiet } from '@fdha/common-utils';

import BasePage from '../../components/BasePage/BasePage';
import { useDebouncedValue, useFilterBy } from '../../hooks';
import { useSortBy } from '../../hooks/useSortBy';

const headCells: HeadCell<CtPatient>[] = [
  {
    id: 'name',
    label: 'Patient Name',
    sortable: true,
    searchable: true,
  },
  {
    id: 'subject_id',
    label: 'Subject ID',
    sortable: true,
    searchable: true,
  },
  {
    id: 'foodProgram:status',
    label: 'Status',
    sortable: false,
    searchable: false,
  },
  {
    id: 'foodProgram:dietPlan:name',
    label: 'Diet Plan',
    sortable: true,
    searchable: true,
  },
  {
    id: 'foodProgram:userDeliveryMenus',
    label: 'Days on Diet',
    sortable: false,
    searchable: false,
  },
  {
    id: 'foodProgram:userDeliveryMenus',
    label: 'Snacks and sides per day',
    sortable: false,
    searchable: false,
  },
];

export const Home = () => {
  const { data, loading } = useMeQuery();
  const navigate = useNavigate();

  const [queryFilterBy, setQueryFilterBy] = useFilterBy<CtPatient>('name', '');
  const queryFilterByDebounced = useDebouncedValue(queryFilterBy);

  const [sortBy, setSortBy] = useSortBy<CtPatient>('name', 'asc');

  const { page, setPage, rowsPerPage, changeRowsPerPage } = useTable();

  const {
    data: patientUserData,
    fetchMore,
    loading: patientUserDataLoading,
    networkStatus,
  } = useListCtPatientsQuery({
    variables: {
      first: rowsPerPage,
      sortBy: {
        sortBy: [sortBy],
      },
      filterBy: {
        filterBy: [queryFilterByDebounced],
      },
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const foodPrograms =
    patientUserData?.ctPatients.edges.map((edge) => edge.node) || [];
  const pageInfo = patientUserData?.ctPatients.pageInfo;
  const totalRowCount = patientUserData?.ctPatients.totalNumberFound;

  const emptyState = queryFilterByDebounced.value
    ? 'No results found'
    : 'No patients yet';

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  const handleNavigate = () => {
    navigate('profile');
  };

  if (loading) {
    return <Loader />;
  }

  const NoneChip = () => <Chip key="none" label="none" color="default" />;

  const renderRow = (foodProgramUser: CtPatient) => {
    const firstUserDeliveryMenu =
      foodProgramUser?.foodProgram?.userDeliveryMenus?.[0];
    const weekdaysOnDiet = foodProgramUser?.foodProgram?.userDeliveryMenus
      ?.length
      ? getConcatWeekdaysOnDiet(foodProgramUser?.foodProgram?.userDeliveryMenus)
      : [];

    return (
      <TableRow hover key={foodProgramUser.id}>
        <TableCell>{foodProgramUser.name}</TableCell>
        <TableCell>{foodProgramUser.subject_id || <NoneChip />}</TableCell>
        <TableCell>
          {foodProgramUser.foodProgram?.status ? (
            getFoodProgramStatus[foodProgramUser.foodProgram?.status]
          ) : (
            <NoneChip />
          )}
        </TableCell>
        <TableCell>{foodProgramUser.foodProgram?.dietPlan.name}</TableCell>
        <TableCell>{getWeekDays(weekdaysOnDiet) || <NoneChip />}</TableCell>
        <TableCell>
          {firstUserDeliveryMenu?.snacksPerDay || <NoneChip />}
        </TableCell>
        <TableCell padding="checkbox">
          <Link to={`/patient/${foodProgramUser.id}`}>
            <Icon
              name="arrow-ios-forward-outline"
              fill={grey[600]}
              size="large"
            />
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <BasePage data-testid="HOME_PANEL">
      <HomeHeader
        userName={data?.me.name}
        picture={data?.me.picture}
        showUserType={false}
        downloadPicture={displayProtectedImage}
        handleNavigate={handleNavigate}
      />
      <SearchableTableHeader<CtPatient>
        headCells={headCells}
        defaultSearchField="name"
        onSearchChange={setQueryFilterBy}
      />
      <Paper>
        <Table<CtPatient>
          actions="right"
          isLoading={patientUserDataLoading}
          initialOrderBy="name"
          headCells={headCells}
          renderRow={renderRow}
          rows={foodPrograms}
          emptyState={emptyState}
          onPageChange={onPageChange}
          onSortChange={setSortBy}
          onRowsPerPageChange={changeRowsPerPage}
          totalRowCount={totalRowCount}
          page={page}
          rowsPerPage={rowsPerPage}
          withPagination
        />
      </Paper>
    </BasePage>
  );
};
