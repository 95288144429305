import { Box, useTheme } from '@mui/material';
import React from 'react';

import { Button, Icon } from '..';

interface ActivityFormActionsProps {
  isEditing?: boolean;
  disableSave?: boolean;
  onCancel?: () => void;
  onUnschedule?: () => void;
}

export const ActivityFormActions: React.FC<ActivityFormActionsProps> = ({
  isEditing = true,
  disableSave,
  onCancel,
  onUnschedule,
}) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      justifyContent={isEditing ? 'space-between' : 'flex-end'}
      mt={4}
    >
      {isEditing && (
        <Box>
          <Box>
            <Button
              color="secondary"
              size="large"
              startIcon={
                <Icon
                  size="medium"
                  fill={theme.palette.secondary.main}
                  name="trash-2-outline"
                />
              }
              onClick={onUnschedule}
              i18nKey="activities:addOrEdit.button"
            >
              Unschedule
            </Button>
          </Box>
        </Box>
      )}

      <Box>
        <Button
          onClick={onCancel}
          color="primary"
          size="medium"
          sx={{ mr: 1 }}
          i18nKey={isEditing ? 'common:button.close' : 'common:button.cancel'}
        >
          {isEditing ? 'Close' : 'Cancel'}
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          size="medium"
          disabled={disableSave}
          i18nKey="common:button.save"
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
