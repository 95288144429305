import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

const DEFAULT_DELAY = 500;

export function useDebouncedValue<T>(value: T, delay = DEFAULT_DELAY) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = debounce(() => setDebouncedValue(value), delay);
    handler();

    return () => {
      handler.cancel();
    };
  }, [value, delay]);

  return debouncedValue;
}
