import { Paper, styled } from '@mui/material';

const PreviewPaper = styled(Paper)(({ theme }) => ({
  maxWidth: '857px',
  width: '100%',
  marginBottom: theme.spacing(4),
  boxShadow: theme.shadows[2],
  alignSelf: 'center',
}));

export default PreviewPaper;
