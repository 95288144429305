import { MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';

import { FlatHeadCell, HeadCell, SpaceBetweenBox } from '..';
import { KeyOfNestedLeaves } from '../../types/keyOfNestedLeaves';

export interface SearchableTableHeaderProps<D extends object> {
  headCells: HeadCell<D>[];
  defaultSearchField: KeyOfNestedLeaves<D>;
  onSearchChange: (field: KeyOfNestedLeaves<D>, value: string) => void;
  disableMargin?: boolean;
}

export function SearchableTableHeader<D extends object>({
  headCells,
  defaultSearchField,
  onSearchChange,
  disableMargin = false,
}: SearchableTableHeaderProps<D>) {
  const [searchField, setSearchField] =
    useState<KeyOfNestedLeaves<D>>(defaultSearchField);
  const [searchQuery, setSearchQuery] = useState('');

  const handleFieldChange = (e: SelectChangeEvent<KeyOfNestedLeaves<D>>) => {
    const value = e.target.value as KeyOfNestedLeaves<D>;
    setSearchField(value);
    onSearchChange(value, searchQuery);
  };

  const handleQueryChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    setSearchQuery(value);
    onSearchChange(searchField, value);
  };

  return (
    <SpaceBetweenBox
      sx={{
        width: '100%',
        marginBottom: disableMargin ? 0 : '16px',
      }}
    >
      <Select
        defaultValue={searchField}
        sx={{
          flex: 1,
        }}
        onChange={handleFieldChange}
        data-testid="FILTER_DROPDOWN"
      >
        {(headCells as FlatHeadCell[])
          .filter((item) => item.searchable)
          .map((item) => {
            const id = item.id.toString();
            return (
              <MenuItem key={id} value={id} data-testid="FILTER_ITEM">
                {item.label}
              </MenuItem>
            );
          })}
      </Select>
      <TextField
        placeholder="Search"
        sx={{
          flex: 6,
          marginLeft: '16px',
        }}
        onChange={handleQueryChange}
        value={searchQuery}
        autoFocus
        data-testid="SEARCH_INPUT"
      />
    </SpaceBetweenBox>
  );
}
