import React, { FC } from 'react';
import { Box, Grid } from '@mui/material';
import {
  ProfileCardHeader,
  LabelBox,
  ProfileCardPaper,
  maskPhone,
} from '@fdha/web-ui-library';

import Avatar from '../Avatar/Avatar';

interface PatientProfileCardProps {
  name: string;
  picture?: string | null;
  email?: string;
  phoneNumber: string;
}

const ProfileCard: FC<PatientProfileCardProps> = ({
  name,
  picture,
  email,
  phoneNumber,
}) => {
  return (
    <ProfileCardPaper>
      <ProfileCardHeader title={name || ''}>
        <Avatar
          picture={picture}
          name={name}
          size={80}
          sx={{ marginLeft: 0 }}
          data-testid="PROFILE_AVATAR"
        />
      </ProfileCardHeader>
      <Box>
        <Grid container rowSpacing={0} columnSpacing={10}>
          <LabelBox
            size={6}
            label="Phone number"
            value={maskPhone(phoneNumber, false)}
          />
          <LabelBox size={6} label="Email" value={email} />
        </Grid>
      </Box>
    </ProfileCardPaper>
  );
};

export default ProfileCard;
