import {
  Autocomplete as MuiAutocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteValue,
  Box,
  Checkbox,
  CircularProgress,
  ListItemText,
  MenuItem,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from '@fdha/common-hooks';

import TextField from '../TextField/TextField';
import { Typography } from '../Typography/Typography';

export interface Option {
  id: string;
  label: string;
  secondaryLabel?: string;
  i18nKey?: string;
}

export interface AutocompleteProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends Omit<
    MuiAutocompleteProps<Option, Multiple, DisableClearable, false>,
    'onOpen' | 'open' | 'renderInput' | 'onChange'
  > {
  onChange: (
    event: React.SyntheticEvent,
    value: AutocompleteValue<Option, Multiple, DisableClearable, false>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Option>
  ) => void;
  title?: string;
  placeholder?: string;
  i18nKeyPlaceholder?: string;
  allowSelectAll?: boolean;
  selectAllLabel?: string;
  v2?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  i18nKeyTitle?: string;
}

export function Autocomplete<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined
>(props: AutocompleteProps<Multiple, DisableClearable>) {
  const { translate } = useTranslation();

  const {
    title,
    placeholder,
    i18nKeyPlaceholder,
    allowSelectAll,
    selectAllLabel = 'Select all',
    onChange: onChangeCallback,
    onClose: onCloseCallback,
    options,
    v2,
    error,
    helperText,
    i18nKeyTitle,
    value,
    ...muiProps
  } = props;

  const [open, setOpen] = useState(false);

  const id = title
    ? `${title.toUpperCase().replace(/ /g, '_')}_AUTOCOMPLETE`
    : 'AUTOCOMPLETE';

  // Multi Autocomplete props
  const localOptions = useMemo(
    () => [
      ...(allowSelectAll && props.multiple
        ? [{ id: 'all', label: selectAllLabel }]
        : []),
      ...options,
    ],
    [allowSelectAll, options, props.multiple, selectAllLabel]
  );

  const isAllSelected = Array.isArray(props.value)
    ? options.length === props.value.length
    : false;

  const multiAutoCompleteProps: Partial<
    AutocompleteProps<Multiple, DisableClearable>
  > = props.multiple ? { limitTags: 2, disableCloseOnSelect: true } : {};

  const didToggleAllSelection = (
    value: AutocompleteValue<Option, Multiple, DisableClearable, false>,
    reason: AutocompleteChangeReason
  ) => {
    if (props.multiple && Array.isArray(value)) {
      if (reason === 'selectOption' || reason === 'removeOption') {
        return value.find((option) => option.id === 'all');
      }
    }
    return false;
  };

  const onChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: AutocompleteValue<Option, Multiple, DisableClearable, false>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Option>
  ) => {
    let newValue = value as AutocompleteValue<
      Option,
      Multiple,
      DisableClearable,
      false
    >;

    const toggledAllSelection = didToggleAllSelection(value, reason);

    if (toggledAllSelection) {
      if (isAllSelected) {
        newValue = [] as unknown as AutocompleteValue<
          Option,
          Multiple,
          DisableClearable,
          false
        >;
      } else {
        newValue = [...options] as AutocompleteValue<
          Option,
          Multiple,
          DisableClearable,
          false
        >;
      }
    }

    onChangeCallback(event, newValue, reason, details);
  };

  const onClose = (
    event: React.SyntheticEvent<Element, Event>,
    reason: AutocompleteCloseReason
  ) => {
    setOpen(false);
    onCloseCallback && onCloseCallback(event, reason);
  };

  const renderOption = (
    optionProps: React.HTMLAttributes<HTMLLIElement>,
    option: Option
  ) => {
    const selectAllProps =
      option.id === 'all' ? { checked: isAllSelected } : {};
    const isChecked = Array.isArray(props.value)
      ? props.value.some((opt) => opt.id === option.id)
      : false;
    const selected =
      option.id === 'all' ? isAllSelected : optionProps['aria-selected'];

    return (
      <MenuItem {...optionProps} key={option.id} aria-selected={selected}>
        {props.multiple && (
          <Checkbox
            disableTouchRipple
            checked={isChecked}
            {...selectAllProps}
          />
        )}
        <ListItemText
          data-testid="AUTOCOMPLETE_LIST_ITEM"
          primary={
            <Typography i18nKey={option?.i18nKey}>{option.label}</Typography>
          }
          secondary={option.secondaryLabel}
        />
      </MenuItem>
    );
  };

  /************ V2 updates *********************/

  const inputSize = v2 ? 'small' : 'medium';
  const titleFontWeight = v2 ? 'bold' : 'medium';
  const titleVariant = v2 ? 'body1' : 'subtitle2';

  /*********************************************/

  return (
    <Box display="flex" flexDirection="column" width="100%" data-testid={id}>
      {title && (
        <Typography
          variant={titleVariant}
          fontWeight={titleFontWeight}
          mb={1}
          i18nKey={i18nKeyTitle}
        >
          {title}
        </Typography>
      )}
      <MuiAutocomplete
        value={value}
        getOptionLabel={(option) =>
          option.i18nKey
            ? translate(option.i18nKey, option.label)
            : option.label
        }
        size={inputSize}
        options={localOptions}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={onClose}
        onChange={onChange}
        renderOption={renderOption}
        data-testid="AUTOCOMPLETE"
        renderInput={(params) => (
          <TextField
            {...params}
            v2={v2}
            error={error}
            helperText={helperText}
            placeholder={placeholder}
            i18nKeyPlaceholder={i18nKeyPlaceholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {open && muiProps.loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        {...multiAutoCompleteProps}
        {...muiProps}
      />
    </Box>
  );
}
