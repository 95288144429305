import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { BasePage } from '@fdha/web-ui-library';
import { BoxProps } from '@mui/material';

interface BasePageProps extends BoxProps {
  ['data-testId']?: string;
}

interface BackButtonProps {
  to?: string;
}

const FoodOpsBasePage: React.FC<BasePageProps> = (props) => {
  return <BasePage {...props}>{props.children}</BasePage>;
};

const BackButton = ({ to = '/' }: BackButtonProps) => {
  return <BasePage.BackButton to={to} component={Link} />;
};

const Title: React.FC<PropsWithChildren> = ({ children }) => {
  return <BasePage.Title>{children}</BasePage.Title>;
};

const Wrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return <BasePage.Wrapper>{children}</BasePage.Wrapper>;
};

export default Object.assign(FoodOpsBasePage, { BackButton, Title, Wrapper });
