import React from 'react';

import { ChangePassword } from '../components';
import LoginBase from '../components/LoginBase';

export const ChangePasswordPage = () => {
  return (
    <LoginBase>
      <ChangePassword />
    </LoginBase>
  );
};
