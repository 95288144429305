import {
  Alert,
  AlertTitle,
  CircularProgress,
  IconButton,
  Box,
  Snackbar as MuiSnackbar,
  useTheme,
  SnackbarOrigin,
} from '@mui/material';
import React, { ReactNode } from 'react';
import Icon from 'react-eva-icons';
import { Trans } from 'react-i18next';

import { useSnackbar } from '../../hooks/useSnackbar';

export type SnackbarVariant = 'default' | 'loading';

const Snackbar = () => {
  const { snackbar, closeSnackbar } = useSnackbar();

  const theme = useTheme();

  const renderAction = () => {
    return (
      <Box flex={1} alignSelf="center">
        <IconButton aria-label="close" color="inherit" onClick={closeSnackbar}>
          <Icon
            name="close-outline"
            fill="currentColor"
            width={20}
            height={20}
          />
        </IconButton>
      </Box>
    );
  };

  /************ V2 updates *********************/

  const anchorOrigin: SnackbarOrigin = snackbar.v2
    ? { vertical: 'top', horizontal: 'right' }
    : { vertical: 'bottom', horizontal: 'left' };
  const action = snackbar.v2 ? undefined : renderAction();

  /*********************************************/

  const getI18nFallbackText = (message: string | ReactNode) =>
    typeof message === 'string' ? message : '';

  const i18nDefaultText = getI18nFallbackText(snackbar.message);

  return (
    <MuiSnackbar
      onClose={snackbar.closeOnClickOutside ? closeSnackbar : () => {}}
      open={snackbar.visible}
      data-testid="SNACK_BAR_ALERT"
      anchorOrigin={anchorOrigin}
    >
      {snackbar.variant === 'loading' ? (
        <Alert
          icon={<CircularProgress color="secondary" />}
          sx={{
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
          }}
          action={action}
        >
          {snackbar.title && (
            <AlertTitle sx={{ color: theme.palette.text.primary }}>
              {snackbar.title}
            </AlertTitle>
          )}
          {snackbar.message}
        </Alert>
      ) : (
        <Alert severity={snackbar.severity} action={action} variant="filled">
          {snackbar.i18nKey ? (
            <Trans i18nKey={snackbar.i18nKey} defaults={i18nDefaultText}>
              {i18nDefaultText}
            </Trans>
          ) : (
            <>
              {snackbar.title && <AlertTitle>{snackbar.title}</AlertTitle>}
              {snackbar.message}
            </>
          )}
        </Alert>
      )}
    </MuiSnackbar>
  );
};

export default Snackbar;
