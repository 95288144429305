import {
  ActionCard,
  ActionItem,
  Button,
  Loader,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import { Box, DialogContentText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { parseISO } from 'date-fns';
import React from 'react';
import {
  Menu,
  SortByOrder,
  useDuplicateMenuMutation,
  useListMenusQuery,
} from '@fdha/graphql-api-foodops';

import AddOrEditMenu from './AddOrEditMenu';

const NUMBER_OF_RESULTS = 10000;

const MenuTab = () => {
  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [duplicateMenu] = useDuplicateMenuMutation();

  const {
    data: menusData,
    refetch: refetchMenus,
    loading,
  } = useListMenusQuery({
    variables: {
      first: NUMBER_OF_RESULTS,
      sortBy: { sortBy: [{ field: 'updated_at', order: SortByOrder.Desc }] },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const menus = menusData?.menus.edges.map((edge) => edge.node) || [];

  const onAddOrEdit = (data?: Menu) => {
    openDialog({
      title: '',
      maxWidth: 'md',
      content: (
        <AddOrEditMenu
          data={data}
          onSuccess={refetchMenus}
          onFinish={closeDialog}
        />
      ),
    });
  };

  const onDuplicateMenu = (id: string) => {
    openDialog({
      title: 'Are you sure you want to duplicate this menu?',
      maxWidth: 'md',
      content: (
        <DialogContentText>
          Your new menu will be displayed on the list.
        </DialogContentText>
      ),
      confirmButtonLabel: 'Duplicate',
      cancelButtonLabel: 'Cancel',
      handleConfirm: async () => await handleDuplicateMenu(id),
    });
  };

  const handleDuplicateMenu = async (menuId: string) => {
    try {
      await duplicateMenu({ variables: { id: menuId } });
      refetchMenus();
    } catch (error) {
      console.error(error);
      showSnackbar({ message: 'Error to duplicate menu', severity: 'error' });
    } finally {
      closeDialog();
    }
  };

  const renderMenuItem = (menu: Menu) => {
    const actionDate = menu.updatedAt || menu.createdAt;

    const actions: ActionItem[] = [
      { type: 'preview', onClick: () => navigate(`/create/menu/${menu.id}`) },
      { type: 'edit', onClick: () => onAddOrEdit(menu) },
      {
        type: 'duplicate',
        onClick: () => onDuplicateMenu(menu.id),
      },
    ];

    return (
      <ActionCard
        key={menu.id}
        title={menu.name}
        actionDate={parseISO(actionDate)}
        actions={actions}
      />
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="secondary"
          data-testid="NEW_MENU"
          size="large"
          startEvaIcon={{ name: 'plus-outline' }}
          sx={{ paddingX: '80px' }}
          onClick={() => onAddOrEdit()}
        >
          Create new
        </Button>
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <Box marginTop={2} display="flex" flexWrap="wrap">
          {menus.map((menu) => renderMenuItem(menu))}
        </Box>
      )}
    </>
  );
};

export default MenuTab;
