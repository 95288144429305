import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { Accordion, CoachInfo, Loader, SelectType } from '@fdha/web-ui-library';
import {
  useGetCtPatientQuery,
  useGetPatientUserQuery,
} from '@fdha/graphql-api-foodops';

import { BasePage, ProfileCard } from '../../components';

import AccountInformation from './AccountInformation';
import FoodProgramInformation from './FoodProgramInformation';
import PlannedPauseInformation from './PlannedPauseInformation';
import DeliveryInformation from './DeliveryInformation';

export const Patient = () => {
  const { patientId } = useParams();
  const theme = useTheme();

  const { data: patientData, loading: patientLoading } = useGetPatientUserQuery(
    {
      variables: {
        id: patientId || '',
      },
    }
  );
  const patient = patientData?.patientUser;
  const coach = {
    id: patient?.coach?.id,
    name: patient?.coach?.name,
  };
  const backupCoach = {
    id: patient?.backup_coach?.id,
    name: patient?.backup_coach?.name,
  };

  const { data: patientUserData, loading: foodProgramLoading } =
    useGetCtPatientQuery({
      variables: {
        id: patientId || '',
      },
      fetchPolicy: 'cache-and-network',
    });
  const trialPatientWithFoodProgramDataV2 = patientUserData?.ctPatient;

  if (patientLoading || foodProgramLoading) {
    return <Loader />;
  }

  return (
    <BasePage>
      <BasePage.BackButton />
      {patient ? (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="stretch"
          flex="1 0 auto"
          flexWrap="nowrap"
        >
          <Box flex={1}>
            <ProfileCard
              name={patient.name}
              email={patient.email}
              phoneNumber={patient.phone_number}
              picture={patient.picture}
            />
            <SelectType
              sx={{
                my: 2,
                backgroundColor: theme.palette.action.disabledBackground,
              }}
              userId={patient.id}
              userType={patient.type}
              fullWidth
              disabled
            />
            <CoachInfo mainCoach={coach} backupCoach={backupCoach} />
          </Box>
          <Stack flex={2} marginLeft={3} spacing={2.5}>
            <Accordion title="Account Information">
              <AccountInformation patient={patient} />
            </Accordion>
            {trialPatientWithFoodProgramDataV2?.foodProgram.id && (
              <>
                <Accordion title="Food Program Information">
                  <FoodProgramInformation
                    foodProgramUser={
                      trialPatientWithFoodProgramDataV2.foodProgram
                    }
                  />
                </Accordion>
                <Accordion title="Planned Pause on Deliveries">
                  <PlannedPauseInformation
                    pauses={
                      trialPatientWithFoodProgramDataV2?.foodProgram
                        ?.plannedPauses || []
                    }
                  />
                </Accordion>
                <DeliveryInformation patientId={patient.id} />
              </>
            )}
          </Stack>
        </Box>
      ) : (
        <Typography>{`No patient found with ID ${patientId}`}</Typography>
      )}
    </BasePage>
  );
};
