import React from 'react';

import { ForgotPassword } from '../components';
import LoginBase from '../components/LoginBase';

export const ForgotPasswordPage = () => {
  return (
    <LoginBase>
      <ForgotPassword />
    </LoginBase>
  );
};
