import { Dish, Menu } from '@fdha/graphql-api-foodops';

export const getOptions = (edges?: { node: Dish | Menu }[]) => {
  return (
    edges?.map(({ node }) => ({
      label: node.name,
      id: node.id,
    })) ?? []
  );
};
