import { AppBar, Box, Link, Tab as MuiTab, Tabs } from '@mui/material';
import React, { SyntheticEvent } from 'react';
import Icon from 'react-eva-icons';

export interface LeftNavTab {
  id: string;
  label: string;
  activeIcon: string;
  inactiveIcon: string;
  to: string;
  component?: React.ElementType;
  ['aria-controls']?: string;
  ['data-testId']?: string;
}

export interface LeftNavProps {
  tabs: LeftNavTab[];
  activeTabId: string;
  onTabChange?: (event: SyntheticEvent<Element, Event>, value: any) => void;
  logo?: string;
  ['data-testId']?: string;
}

export const LeftNav: React.FC<LeftNavProps> = (props) => {
  const getIcon = (tab: LeftNavTab) => {
    let icon = tab.inactiveIcon;

    if (tab.id === props.activeTabId) {
      icon = tab.activeIcon;
    }

    return (
      <Box key={icon}>
        <Icon name={icon} size="large" />
      </Box>
    );
  };

  return (
    <AppBar
      elevation={0}
      position="static"
      sx={{
        justifyContent: 'center',
        width: 104,
        border: 'none',
        position: 'relative',
      }}
      data-testid={props['data-testId']}
    >
      {props.logo && (
        <img
          data-testid="FAETH_LOGO"
          src={props.logo}
          alt="logo"
          style={{
            position: 'absolute',
            top: '40px',
            left: '24px',
            width: '56px',
          }}
        />
      )}
      <Tabs
        onChange={props.onTabChange}
        orientation="vertical"
        value={props.activeTabId}
        variant="scrollable"
      >
        {props.tabs.map((tab, index) => (
          <MuiTab
            aria-controls={tab['aria-controls']}
            icon={getIcon(tab)}
            id={tab.id}
            key={tab.id}
            label={tab.label}
            value={tab.id}
            component={tab.component || Link}
            to={tab.to}
            sx={{ fontSize: '0.875rem' }}
            data-testid={tab['data-testId']}
          />
        ))}
      </Tabs>
    </AppBar>
  );
};
