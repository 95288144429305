"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useChat = exports.ChatProvider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var stream_chat_1 = require("stream-chat");
var Context = (0, react_1.createContext)({
    addListener: function () { return function () { }; },
    removeListener: function () { },
    isLoading: false,
    unreadCount: 0,
});
var ChatProvider = function (_a) {
    var props = __rest(_a, []);
    var streamKey = props.streamKey, user = props.user;
    var _b = (0, react_1.useState)(undefined), client = _b[0], setClient = _b[1];
    var _c = (0, react_1.useState)(false), isLoading = _c[0], setIsLoading = _c[1];
    var _d = (0, react_1.useState)(undefined), lastReadId = _d[0], setLastReadId = _d[1];
    var _e = (0, react_1.useState)(), streamUserId = _e[0], setStreamUserId = _e[1];
    var _f = (0, react_1.useState)(), unreadCount = _f[0], setUnreadCount = _f[1];
    (0, react_1.useEffect)(function () {
        var chatClient;
        var isMounted = true;
        var initChat = function () { return __awaiter(void 0, void 0, void 0, function () {
            var e_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!streamKey || !(user === null || user === void 0 ? void 0 : user.streamUserId)) {
                            return [2 /*return*/];
                        }
                        setIsLoading(true);
                        chatClient = stream_chat_1.StreamChat.getInstance(streamKey);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, chatClient.connectUser({
                                id: user.streamUserId,
                                name: user.name,
                                image: user.picture,
                            }, user.streamUserToken)];
                    case 2:
                        _b.sent();
                        if (isMounted) {
                            setClient(chatClient);
                            setStreamUserId(user.streamUserId);
                            setUnreadCount((_a = chatClient.user) === null || _a === void 0 ? void 0 : _a.total_unread_count);
                            setIsLoading(false);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        console.log('Unable to connect to chat', e_1);
                        setIsLoading(false);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        initChat();
        return function () {
            chatClient === null || chatClient === void 0 ? void 0 : chatClient.disconnectUser();
            isMounted = false;
        };
    }, [
        streamKey,
        user === null || user === void 0 ? void 0 : user.name,
        user === null || user === void 0 ? void 0 : user.picture,
        user === null || user === void 0 ? void 0 : user.streamUserId,
        user === null || user === void 0 ? void 0 : user.streamUserToken,
    ]);
    (0, react_1.useEffect)(function () {
        var eventListener = client === null || client === void 0 ? void 0 : client.on(function (event) {
            var _a;
            var eventsToObserveForUnreadCount = [
                'message.new',
                'notification.message_new',
                'notification.mark_read',
            ];
            if (eventsToObserveForUnreadCount.includes(event.type)) {
                setUnreadCount(event.total_unread_count || 0);
            }
            var eventsToObserveForReadCheck = ['message.read'];
            if (eventsToObserveForReadCheck.includes(event.type) &&
                event.received_at) {
                /* This is necessary for confirmation icon on channelPreview,
                we need to render the page again when a user read to update
                last_read value. Without this read icon doesn't work properly.*/
                setLastReadId((_a = event.user) === null || _a === void 0 ? void 0 : _a.id);
            }
        });
        return function () {
            eventListener === null || eventListener === void 0 ? void 0 : eventListener.unsubscribe();
        };
    }, [client]);
    var handleAddListener = (0, react_1.useCallback)(function (cb) {
        var customListener = function (event) {
            if (event.type !== 'health.check') {
                cb(event);
            }
        };
        client === null || client === void 0 ? void 0 : client.on(customListener);
        return customListener;
    }, [client]);
    var handleRemoveListener = (0, react_1.useCallback)(function (cb) {
        return client === null || client === void 0 ? void 0 : client.off(cb);
    }, [client]);
    return ((0, jsx_runtime_1.jsx)(Context.Provider, { value: {
            addListener: handleAddListener,
            removeListener: handleRemoveListener,
            client: client,
            isLoading: isLoading,
            streamUserId: streamUserId,
            lastReadId: lastReadId,
            unreadCount: unreadCount || 0,
        }, children: props.children }));
};
exports.ChatProvider = ChatProvider;
var useChat = function () { return (0, react_1.useContext)(Context); };
exports.useChat = useChat;
