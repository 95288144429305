"use strict";
//
// Activity Detector module, based on 'activity-detector' package on npm
// Reference: https://www.npmjs.com/package/activity-detector
// Ported to TS by: Thiago Sigrist <thiago.sigrist@encora.com>
//
Object.defineProperty(exports, "__esModule", { value: true });
exports.createActivityDetector = void 0;
var ACTIVE = 'active';
var IDLE = 'idle';
var DEFAULT_INITIAL_STATE = ACTIVE;
var DEFAULT_ACTIVITY_EVENTS = [
    'click',
    'mousemove',
    'keydown',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'focus',
];
var DEFAULT_INACTIVITY_EVENTS = [
    'blur',
    'visibilitychange',
];
var DEFAULT_IGNORED_EVENTS_WHEN_IDLE = [
    'mousemove',
];
var BROWSER_PREFIXES = ['webkit', 'moz', 'ms'];
var hidden, visibilityChangeEvent;
if (typeof document !== 'undefined') {
    if (typeof document.hidden !== 'undefined') {
        hidden = 'hidden';
        visibilityChangeEvent = 'visibilitychange';
    }
    else {
        for (var i = 0; i < BROWSER_PREFIXES.length; i++) {
            var prefix = BROWSER_PREFIXES[i];
            if (typeof document["".concat(prefix, "Hidden")] !== 'undefined') {
                hidden = "".concat(prefix, "Hidden");
                visibilityChangeEvent = "".concat(prefix, "visibilitychange");
                break;
            }
        }
    }
}
/**
 * Creates an activity detector instance
 * @param {ActivityDetectorOptions} options
 * @returns {Object} activity detector instance
 * @returns {Function} init - Starts the activity detector with the given state
 * @returns {Function} on - Register an event listener for the required event
 * @returns {Function} stop - Stops the activity detector and clean the listeners
 */
var createActivityDetector = function (_a) {
    var _b;
    var _c = _a === void 0 ? {} : _a, _d = _c.activityEvents, activityEvents = _d === void 0 ? DEFAULT_ACTIVITY_EVENTS : _d, _e = _c.inactivityEvents, inactivityEvents = _e === void 0 ? DEFAULT_INACTIVITY_EVENTS : _e, _f = _c.ignoredEventsWhenIdle, ignoredEventsWhenIdle = _f === void 0 ? DEFAULT_IGNORED_EVENTS_WHEN_IDLE : _f, _g = _c.timeToIdle, timeToIdle = _g === void 0 ? 30000 : _g, _h = _c.initialState, initialState = _h === void 0 ? DEFAULT_INITIAL_STATE : _h, _j = _c.autoInit, autoInit = _j === void 0 ? true : _j;
    var listeners = (_b = {}, _b[ACTIVE] = [], _b[IDLE] = [], _b);
    var state;
    var timer;
    var setState = function (newState) {
        clearTimeout(timer);
        if (newState === ACTIVE) {
            timer = setTimeout(function () { return setState(IDLE); }, timeToIdle);
        }
        if (state !== newState) {
            state = newState;
            listeners[state].forEach(function (l) { return l(); });
        }
    };
    var handleUserActivityEvent = function (event) {
        if (state === ACTIVE || ignoredEventsWhenIdle.indexOf(event.type) < 0) {
            setState(ACTIVE);
        }
    };
    var handleUserInactivityEvent = function () {
        setState(IDLE);
    };
    var handleVisibilityChangeEvent = function () {
        setState(document[hidden] ? IDLE : ACTIVE);
    };
    /**
     * Starts the activity detector with the given state.
     * @param {string} firstState 'idle' or 'active'
     */
    var init = function (firstState) {
        if (firstState === void 0) { firstState = DEFAULT_INITIAL_STATE; }
        setState(firstState === ACTIVE ? ACTIVE : IDLE);
        activityEvents.forEach(function (eventName) {
            return window.addEventListener(eventName, handleUserActivityEvent);
        });
        inactivityEvents
            .filter(function (eventName) { return eventName !== 'visibilitychange'; })
            .forEach(function (eventName) {
            return window.addEventListener(eventName, handleUserInactivityEvent);
        });
        if (inactivityEvents.indexOf('visibilitychange') >= 0 &&
            visibilityChangeEvent) {
            document.addEventListener(visibilityChangeEvent, handleVisibilityChangeEvent);
        }
    };
    /**
     * Register an event listener for the required event
     * @param {string} eventName 'active' or 'idle'
     * @param {Function} listener listener function to register
     * @returns {Function} off - Unregister the listener
     */
    var on = function (eventName, listener) {
        listeners[eventName].push(listener);
        var off = function () {
            var index = listeners[eventName].indexOf(listener);
            if (index >= 0) {
                listeners[eventName].splice(index, 1);
            }
        };
        return off;
    };
    /**
     * Stops the activity detector and clean the listeners
     */
    var stop = function () {
        listeners[ACTIVE] = [];
        listeners[IDLE] = [];
        clearTimeout(timer);
        activityEvents.forEach(function (eventName) {
            return window.removeEventListener(eventName, handleUserActivityEvent);
        });
        inactivityEvents.forEach(function (eventName) {
            return window.removeEventListener(eventName, handleUserInactivityEvent);
        });
        if (visibilityChangeEvent) {
            document.removeEventListener(visibilityChangeEvent, handleVisibilityChangeEvent);
        }
    };
    if (autoInit) {
        init(initialState);
    }
    return { on: on, stop: stop, init: init };
};
exports.createActivityDetector = createActivityDetector;
