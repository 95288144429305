import { InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import { getToken } from '@fdha/common-utils';
import { createUploadLink } from 'apollo-upload-client';
import fdhaConfig from '@fdha/aws-config-foodops';

const httpLink = createUploadLink({
  uri: fdhaConfig.api.foodOpsApiBaseUrl,
  headers: {
    'x-api-key': fdhaConfig.api.apiKey,
    'apollo-require-preflight': true,
  },
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getToken();
  return {
    headers: {
      ...headers,
      Authorization: token,
    },
  };
});

export const apolloClientConfig = {
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          shipments: relayStylePagination(),
          ctPatients: relayStylePagination(),
        },
      },
    },
  }),
};
