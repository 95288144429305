import { Box, useTheme } from '@mui/material';
import React, { FC, useMemo } from 'react';
import {
  getShipmentStatusLabelByValue,
  DeliveryShipmentStatus as TrackingStatus,
} from '@fdha/common-utils';

import { Typography } from '../Typography/Typography';
import { formatUTCDate } from '../../utils';
import { ShipmentStatusIcon } from '../ShipmentStatusIcon/ShipmentStatusIcon';
import { Icon } from '../Icon/Icon';
import { DeliveryStatusEnum } from '../../types/index';
export interface DeliveryStatusProps {
  openChangesDate: string;
  status: 'open' | 'closed';
  isOwner: boolean;
  trackingStatus?: TrackingStatus;
  isCanceled?: boolean;
  showBoldDate?: boolean;
}

export const DeliveryStatus: FC<DeliveryStatusProps> = ({
  openChangesDate,
  status,
  isOwner,
  trackingStatus,
  isCanceled = false,
  showBoldDate = false,
}) => {
  const theme = useTheme();

  const statusText = useMemo(() => {
    const shipmentStatus = getShipmentStatusLabelByValue('web');
    if (isCanceled) {
      return 'The delivery has been canceled';
    }

    if (status === DeliveryStatusEnum.Open) {
      return showBoldDate ? (
        <Typography variant="body2">
          Open until{' '}
          <b>{formatUTCDate(openChangesDate, 'monthDay')} at 9 AM ET</b>
        </Typography>
      ) : (
        `Open until ${formatUTCDate(openChangesDate, 'monthDay')} at 9 AM ET`
      );
    }

    if (!trackingStatus) {
      return 'Closed for Changes';
    }

    if (isOwner) {
      return shipmentStatus[trackingStatus].fallback;
    }

    if (trackingStatus === TrackingStatus.BeingPrepared) {
      return 'The food is being prepared';
    }

    if (trackingStatus === TrackingStatus.Delivered) {
      return 'The delivery has arrived';
    }

    if (trackingStatus === TrackingStatus.Transit) {
      return 'The delivery is en route';
    }

    return shipmentStatus[trackingStatus].fallback;
  }, [
    isCanceled,
    isOwner,
    openChangesDate,
    showBoldDate,
    status,
    trackingStatus,
  ]);

  const statusIcon = useMemo(() => {
    if (isCanceled) {
      return (
        <Icon
          name="close-circle-outline"
          size="medium"
          fill={theme.palette.text.primary}
        />
      );
    }
    if (status === DeliveryStatusEnum.Open) {
      return (
        <Icon
          name="unlock-outline"
          size="medium"
          fill={theme.palette.success.main}
        />
      );
    }

    if (!trackingStatus) {
      return (
        <Icon
          name="lock-outline"
          size="medium"
          fill={theme.palette.error.main}
        />
      );
    }

    return <ShipmentStatusIcon status={trackingStatus} size="medium" />;
  }, [
    isCanceled,
    status,
    theme.palette.error.main,
    theme.palette.success.main,
    theme.palette.text.primary,
    trackingStatus,
  ]);

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {statusIcon}
      <Typography
        variant="subtitle2"
        color={theme.palette.text.secondary}
        marginLeft="4px"
        fontWeight={400}
        fontSize={13}
      >
        {statusText}
      </Typography>
    </Box>
  );
};
