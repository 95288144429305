import React, { PropsWithChildren } from 'react';

export interface ButtonUnstyledProps {
  style?: React.CSSProperties;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ButtonUnstyled: React.FC<
  PropsWithChildren<ButtonUnstyledProps>
> = ({ style, onClick, children }) => {
  return (
    <button
      style={{
        border: 'none',
        background: 'none',
        cursor: 'pointer',
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
