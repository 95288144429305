"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTranslation = exports.TranslationProvider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_i18next_1 = require("react-i18next");
var Context = (0, react_1.createContext)({
    translate: function (value) { return value; },
});
var TranslationProvider = function (_a) {
    var children = _a.children, i18n = _a.i18n;
    var t = (0, react_i18next_1.useTranslation)(undefined, { i18n: i18n }).t;
    var handleTranslate = function (key, defaultValue, params) {
        if (defaultValue === void 0) { defaultValue = ''; }
        return t(key, defaultValue, params);
    };
    return ((0, jsx_runtime_1.jsx)(Context.Provider, { value: {
            translate: handleTranslate,
        }, children: children }));
};
exports.TranslationProvider = TranslationProvider;
var useTranslation = function () { return (0, react_1.useContext)(Context); };
exports.useTranslation = useTranslation;
