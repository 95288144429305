import React from 'react';

import { ResetPassword } from '../components';
import LoginBase from '../components/LoginBase';

export const ResetPasswordPage = () => {
  return (
    <LoginBase>
      <ResetPassword />
    </LoginBase>
  );
};
