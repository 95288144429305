import React from 'react';
import { useNavigate, useParams } from 'react-router';

import { BasePage } from '../../../../components';

import AddOrEditDietPlan from './AddOrEditDietPlan';

const EditDietPlan = () => {
  const { dietPlanId } = useParams();

  const navigate = useNavigate();
  const parentRoute = '/create/diet-plan';

  return (
    <BasePage>
      <BasePage.BackButton to={parentRoute} />
      <BasePage.Wrapper>
        <BasePage.Title>Edit Diet Plan</BasePage.Title>
        <AddOrEditDietPlan
          dietPlanId={dietPlanId}
          onCancel={() => navigate(parentRoute)}
          onSuccess={() => navigate(parentRoute)}
        />
      </BasePage.Wrapper>
    </BasePage>
  );
};

export default EditDietPlan;
