import React, { FC } from 'react';
import {
  Card as MuiCard,
  IconButton,
  Stack,
  useTheme,
  styled,
  Box,
  Divider,
} from '@mui/material';

import { DataVariation, Icon, SkeletonWrapper, Typography } from '..';
import { formatUTCDate, useDialog } from '../..';

interface AssayResult {
  variation: number;
  eventDate: string;
  baseline: number;
  value: number;
  target: number;
}

const Card = styled(MuiCard)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  ':hover': {
    backgroundColor: theme.palette.background.paper,
  },
}));

export interface DataCardProps {
  data?: AssayResult;
  baselineDate: string;
  targetValue: number;
  showSkeleton: boolean;
}

export const DataCard: FC<DataCardProps> = ({
  data,
  baselineDate,
  targetValue,
  showSkeleton,
}) => {
  const theme = useTheme();
  const { openDialogV2, closeDialog } = useDialog();

  const handleTarget = () => {
    openDialogV2({
      title: 'Target',
      content: (
        <>
          <Typography i18nKey="dataVisualization:aa.targetDialog.content.text1">
            The target is the expected decrease in your amino acid levels when
            you are you on the study diet.
          </Typography>
          <Typography
            mt={3}
            i18nKey="dataVisualization:aa.targetDialog.content.text2"
          >
            The purpose of this study is to see whether lowered levels of these
            amino acids can slow tumor growth.
          </Typography>
        </>
      ),
      confirmButtonLabel: 'Close',
      handleConfirm: async () => closeDialog(),
      showCloseButton: true,
      i18nKeyTitle: 'dataVisualization:aa.targetDialog.title',
    });
  };

  const handleBaseline = () => {
    openDialogV2({
      title: 'How it works',
      content: (
        <>
          <Typography i18nKey="dataVisualization:aa.baselineDialog.content.text1">
            The baseline level represents your amino acid levels when you first
            joined the trial.
          </Typography>
          <Typography
            mt={3}
            i18nKey="dataVisualization:aa.baselineDialog.content.text2"
          >
            Follow the guidelines of your study diet to lower these amino acid
            levels as much as possible.
          </Typography>
        </>
      ),
      confirmButtonLabel: 'Close',
      handleConfirm: async () => closeDialog(),
      showCloseButton: true,
      i18nKeyTitle: 'dataVisualization:aa.baselineDialog.title',
    });
  };

  const renderDate = (newDate?: string) => (
    <Typography
      variant="caption"
      sx={{ color: theme.palette.text.secondary }}
      i18nKey="dataVisualization:aa.date"
      i18nParams={{ date: newDate || '' }}
    >
      {newDate && formatUTCDate(newDate, 'longMonthDayYear')}
    </Typography>
  );

  const renderInformation = (
    text: string,
    value: number = 0,
    handleInfo: () => void,
    subText?: string,
    i18nKey?: string
  ) => (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack>
        <Stack direction="row" alignItems="center">
          <Typography
            variant="body2"
            fontWeight="bold"
            color={theme.palette.text.secondary}
            i18nKey={i18nKey}
          >
            {text.toUpperCase()}
          </Typography>
          <IconButton onClick={handleInfo}>
            <Icon
              name="alert-circle-outline"
              size="medium"
              fill={theme.palette.text.secondary}
            />
          </IconButton>
        </Stack>
        {renderDate(subText)}
      </Stack>
      <Typography variant="h4">{value.toFixed(2)}</Typography>
    </Stack>
  );

  return (
    <SkeletonWrapper isLoading={showSkeleton}>
      <Card>
        <Stack spacing={2} padding={1.75}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="body2"
              fontWeight="bold"
              i18nKey="dataVisualization:aa.latestReading"
            >
              LATEST READING
            </Typography>
            {renderDate(data?.eventDate)}
          </Box>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography variant="h3">{data?.value?.toFixed(2)}</Typography>
            {data && (
              <DataVariation
                variant="body2"
                variation={data.variation}
                target={data.target}
              />
            )}
          </Stack>
          <Divider />
          {renderInformation(
            'Target',
            targetValue,
            handleTarget,
            undefined,
            'dataVisualization:aa.targetUpperCase'
          )}
          <Divider />
          {renderInformation(
            'Baseline',
            data?.baseline,
            handleBaseline,
            baselineDate,
            'dataVisualization:aa.baselineUpperCase'
          )}
        </Stack>
      </Card>
    </SkeletonWrapper>
  );
};
