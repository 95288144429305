"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseBackendError = void 0;
exports.getFormikError = getFormikError;
var formik_1 = require("formik");
var handledErrorCodes = ['BAD_USER_INPUT', 'NOT_FOUND', 'FORBIDDEN'];
var parseBackendError = function (error, customMessage) {
    var defaultMessage = 'Sorry, something went wrong. Please try again later.';
    var handledError = error.graphQLErrors.find(function (graphqlError) {
        if (typeof graphqlError.extensions.code === 'string') {
            return handledErrorCodes.includes(graphqlError.extensions.code);
        }
        else {
            return undefined;
        }
    });
    return (handledError === null || handledError === void 0 ? void 0 : handledError.message) || customMessage || defaultMessage;
};
exports.parseBackendError = parseBackendError;
function getFormikError(name, errors, touched) {
    var errorMsg = (0, formik_1.getIn)(errors, name);
    var hasTouched = (0, formik_1.getIn)(touched, name);
    return hasTouched && errorMsg ? errorMsg : null;
}
