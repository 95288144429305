import { DietPlanV2 } from '@fdha/graphql-api-foodops';
import { utcToZonedTime } from 'date-fns-tz';

interface MenuUI {
  id: string;
  name?: string;
}

interface WeekDelivery {
  label: string;
  sequenceNumber: number;
  menu: MenuUI | null;
}

export interface Week {
  firstDelivery: WeekDelivery;
  secondDelivery: WeekDelivery;
}

interface DietPlanUI {
  name: string;
  initialDate: Date;
  firstDeliveryQtd?: number;
  secondDeliveryQtd?: number;
  menusBySequenceNumber: MenusBySequenceNumber;
}

export type MenusBySequenceNumber = {
  [sequenceNumber: number]: MenuUI | null;
};

export const convertDietPlanToUIFormat = (dietPlan: DietPlanV2): DietPlanUI => {
  let menusBySequenceNumber: MenusBySequenceNumber = {};
  dietPlan.deliveries.forEach((delivery) => {
    menusBySequenceNumber[delivery.sequenceNumber] = delivery.menu
      ? {
          id: delivery.menu.id,
          name: delivery.menu.name,
        }
      : null;
  });

  return {
    name: dietPlan.name,
    initialDate: utcToZonedTime(dietPlan.startDate, 'UTC'),
    menusBySequenceNumber,
  };
};

export const buildWeeks = (menusBySequenceNumber: MenusBySequenceNumber) => {
  let weekCharCode = 65; // Uppercase A char code

  let weeks: Week[] = [];

  for (let i = 1; i < Object.keys(menusBySequenceNumber).length; i += 2) {
    const week = {
      firstDelivery: {
        label: getWeekLabel(String.fromCharCode(weekCharCode), true),
        menu: menusBySequenceNumber[i],
        sequenceNumber: i,
      },
      secondDelivery: {
        label: getWeekLabel(String.fromCharCode(weekCharCode), false),
        menu: menusBySequenceNumber[i + 1],
        sequenceNumber: i + 1,
      },
    };

    weeks.push(week);
    weekCharCode += 1;
  }

  return weeks;
};

const getWeekLabel = (weekCode: string, isFirstDelivery: boolean) => {
  const dayOfWeek = isFirstDelivery ? 'Tuesday' : 'Friday';

  return `Week ${weekCode}: ${dayOfWeek}`;
};
