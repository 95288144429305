import {
  Button,
  TextField,
  Autocomplete,
  useSnackbar,
  Loader,
  Option,
} from '@fdha/web-ui-library';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { GraphQLError } from 'graphql';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { groupBy } from 'lodash';
import {
  Dish,
  Menu,
  MenuInput,
  useAddMenuMutation,
  useListDishesQuery,
  useUpdateMenuMutation,
} from '@fdha/graphql-api-foodops';

const NUMBER_OF_RESULTS = 10000;

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required(),
  breakfast: Yup.array().min(1),
  lunch: Yup.array().min(1),
  dinner: Yup.array().min(1),
  snacks: Yup.array().min(1),
});

interface MenuSchema {
  name: string;
  breakfast: Option[];
  lunch: Option[];
  dinner: Option[];
  snacks: Option[];
  sides: Option[];
}

interface AddOrEditMenuProps {
  data?: Menu;
  onSuccess?: () => void;
  onFinish?: () => void;
}

const AddOrEditMenu: React.FC<AddOrEditMenuProps> = ({
  data,
  onSuccess,
  onFinish,
}) => {
  const { showSnackbar } = useSnackbar();

  const [addMenu] = useAddMenuMutation();
  const [updateMenu] = useUpdateMenuMutation();

  const { data: dishesData, loading: loadingDishes } = useListDishesQuery({
    variables: { first: NUMBER_OF_RESULTS },
    fetchPolicy: 'cache-and-network',
  });

  const getDishesOptions = (dishes: Dish[]) =>
    dishes?.map((dish: Dish) => ({
      label: dish.name,
      id: dish.id,
    })) ?? [];

  const dishesNodes = dishesData?.dishes.edges.map((edge) => edge.node);
  const dishesGroupByType = dishesNodes ? groupBy(dishesNodes, 'type') : {};
  const mealDishes = dishesGroupByType?.meal;
  const snackDishes = dishesGroupByType?.snack;

  const { mealDishesOptions, snackDishesOptions } = useMemo(() => {
    return {
      mealDishesOptions: getDishesOptions(mealDishes),
      snackDishesOptions: getDishesOptions(snackDishes),
    };
  }, [mealDishes, snackDishes]);

  const initialValues: MenuSchema = {
    name: data?.name || '',
    breakfast:
      mealDishesOptions.filter((opt) => data?.breakfast.includes(opt.id)) || [],
    lunch:
      mealDishesOptions.filter((opt) => data?.lunch.includes(opt.id)) || [],
    dinner:
      mealDishesOptions.filter((opt) => data?.dinner.includes(opt.id)) || [],
    snacks:
      snackDishesOptions.filter((opt) => data?.snacks.includes(opt.id)) || [],
    sides:
      snackDishesOptions.filter((opt) => data?.sides.includes(opt.id)) || [],
  };

  const handleSubmit = async (values: MenuSchema) => {
    const payload: MenuInput = {
      name: values.name,
      breakfast: values.breakfast.map((b) => b.id),
      lunch: values.lunch.map((l) => l.id),
      dinner: values.dinner.map((d) => d.id),
      snacks: values.snacks.map((s) => s.id),
      sides: values.sides.map((sd) => sd.id),
    };

    try {
      if (data) {
        await updateMenu({ variables: { menuId: data.id, input: payload } });
      } else {
        await addMenu({ variables: { input: payload } });
      }

      const action = data ? 'updated' : 'created';
      const message = `Menu ${action} with success`;

      showSnackbar({
        message,
        severity: 'success',
      });
      onSuccess && onSuccess();
    } catch (e: any) {
      const handledError = e.graphQLErrors.find(
        (error: GraphQLError) => error.extensions?.code === 'BAD_USER_INPUT'
      );
      const message = handledError?.message || 'Error to create menu';

      showSnackbar({
        message,
        severity: 'error',
      });
    } finally {
      onFinish && onFinish();
    }
  };

  return (
    <Stack spacing={3}>
      <Typography variant="h5">Create Menu</Typography>
      {loadingDishes ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validateOnMount
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleChange, isSubmitting, isValid, setFieldValue, values }) => {
            return (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      title="Name"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      multiple
                      allowSelectAll
                      title="Breakfast"
                      options={mealDishesOptions}
                      defaultValue={[]}
                      value={values.breakfast}
                      loading={loadingDishes}
                      onChange={(_event, value) => {
                        setFieldValue('breakfast', value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={3} mt={3}>
                  <Grid item xs={6}>
                    <Stack spacing={3}>
                      <Autocomplete
                        multiple
                        allowSelectAll
                        title="Lunch"
                        options={mealDishesOptions}
                        defaultValue={[]}
                        value={values.lunch}
                        loading={loadingDishes}
                        onChange={(_event, value) => {
                          setFieldValue('lunch', value);
                        }}
                      />
                      <Autocomplete
                        multiple
                        allowSelectAll
                        title="Snacks"
                        options={snackDishesOptions}
                        defaultValue={[]}
                        value={values.snacks}
                        loading={loadingDishes}
                        onChange={(_event, value) => {
                          setFieldValue('snacks', value);
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={3}>
                      <Autocomplete
                        multiple
                        allowSelectAll
                        title="Dinner"
                        options={mealDishesOptions}
                        defaultValue={[]}
                        value={values.dinner}
                        loading={loadingDishes}
                        onChange={(_event, value) => {
                          setFieldValue('dinner', value);
                        }}
                      />
                      <Autocomplete
                        multiple
                        allowSelectAll
                        title="Sides"
                        options={snackDishesOptions}
                        defaultValue={[]}
                        value={values.sides}
                        loading={loadingDishes}
                        onChange={(_event, value) => {
                          setFieldValue('sides', value);
                        }}
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  columnGap={1}
                  marginTop={3}
                >
                  <Button color="primary" size="large" onClick={onFinish}>
                    CANCEL
                  </Button>
                  <Button
                    disabled={!isValid || isSubmitting}
                    variant="contained"
                    color="secondary"
                    size="large"
                    type="submit"
                  >
                    SAVE
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      )}
    </Stack>
  );
};

export default AddOrEditMenu;
