import { Box, IconButton } from '@mui/material';
import React, { FC, Dispatch, SetStateAction } from 'react';
import { parseISO } from 'date-fns';
import { grey } from '@mui/material/colors';
import Icon from 'react-eva-icons';

import { SpaceBetweenBox, Typography } from '..';
import { formatDate } from '../..';

import { UserData } from './ProfileCard';

interface ViewModeCardProps {
  user?: UserData;
  handleEditMode: Dispatch<SetStateAction<boolean>>;
  editable: boolean;
}

function ProfileCardLabel(props: {
  label: string;
  data?: string;
  dataTestId?: string;
}) {
  return (
    <Box
      marginTop={4}
      sx={{ overflowWrap: 'break-word' }}
      width="50%"
      data-testid={props.dataTestId}
    >
      <Typography variant="subtitle2">{props.label}</Typography>
      <Typography>{props.data || ''}</Typography>
    </Box>
  );
}

const ViewModeCard: FC<ViewModeCardProps> = (props) => {
  return (
    <Box>
      <SpaceBetweenBox>
        <ProfileCardLabel
          label="Name"
          data={props.user?.name || '-'}
          dataTestId="PROFILE_NAME"
        />
        <ProfileCardLabel
          label="Email"
          data={props.user?.email || '-'}
          dataTestId="PROFILE_EMAIL"
        />
      </SpaceBetweenBox>
      <SpaceBetweenBox>
        <ProfileCardLabel
          label="Joined on"
          data={
            props.user?.createdAt
              ? formatDate(parseISO(props.user?.createdAt))
              : '-'
          }
          dataTestId="PROFILE_JOINED_ON"
        />
        <ProfileCardLabel
          label="Phone number"
          data={props.user?.phoneNumber || '-'}
          dataTestId="PROFILE_PHONE_NUMBER"
        />
      </SpaceBetweenBox>
      {props.editable && (
        <Box textAlign="end">
          <IconButton
            onClick={() => props.handleEditMode(true)}
            data-testid="EDIT_BUTTON"
          >
            <Icon name="edit-outline" width={24} height={24} fill={grey[600]} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default ViewModeCard;
