import {
  ActionCard,
  ActionItem,
  Button,
  Loader,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import { Box, DialogContentText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { parseISO } from 'date-fns';
import React from 'react';
import {
  SortByOrder,
  useListDietPlansQuery,
  useDuplicateDietPlanMutation,
  DietPlanV2,
} from '@fdha/graphql-api-foodops';

const NUMBER_OF_RESULTS = 10000;

const DietPlanTab = () => {
  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [duplicateDietPlan] = useDuplicateDietPlanMutation();

  const {
    data: dietPlansData,
    refetch: refetchDietPlans,
    loading,
  } = useListDietPlansQuery({
    variables: {
      first: NUMBER_OF_RESULTS,
      sortBy: { sortBy: [{ field: 'updated_at', order: SortByOrder.Desc }] },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const dietPlans =
    dietPlansData?.dietPlansV2.edges.map((edge) => edge.node) || [];

  const onDuplicateDietPlan = (id: string) => {
    openDialog({
      title: 'Are you sure you want to duplicate this diet plan?',
      maxWidth: 'md',
      content: (
        <DialogContentText>
          Your new diet plan will be displayed on the list.
        </DialogContentText>
      ),
      confirmButtonLabel: 'Duplicate',
      cancelButtonLabel: 'Cancel',
      handleConfirm: async () => await handleDuplicateDietPlan(id),
    });
  };

  const handleDuplicateDietPlan = async (dietPlanId: string) => {
    try {
      await duplicateDietPlan({ variables: { dietPlanId } });
      refetchDietPlans();
    } catch (error) {
      console.error(error);
      showSnackbar({
        message: 'Error to duplicate diet plan',
        severity: 'error',
      });
    } finally {
      closeDialog();
    }
  };
  const renderDietPlanItem = (dietPlan: DietPlanV2) => {
    const actionDate = dietPlan.updatedAt || dietPlan.createdAt;

    const actions: ActionItem[] = [
      {
        type: 'preview',
        onClick: () => navigate(`/create/diet-plan/${dietPlan.id}`),
      },
      {
        type: 'edit',
        onClick: () => navigate(`/create/diet-plan/edit/${dietPlan.id}`),
      },
      {
        type: 'duplicate',
        onClick: () => onDuplicateDietPlan(dietPlan.id),
      },
    ];

    return (
      <ActionCard
        key={dietPlan.id}
        title={dietPlan.name}
        actionDate={parseISO(actionDate)}
        actions={actions}
      />
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="secondary"
          data-testid="NEW_DIET_PLAN"
          size="large"
          startEvaIcon={{ name: 'plus-outline' }}
          sx={{ paddingX: '80px' }}
          onClick={() => navigate('/create/diet-plan/add')}
        >
          Create new
        </Button>
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <Box marginTop={2} display="flex" flexWrap="wrap">
          {dietPlans.map(renderDietPlanItem)}
        </Box>
      )}
    </>
  );
};

export default DietPlanTab;
