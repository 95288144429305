import React, { useMemo } from 'react';
import {
  DeliveryShipmentStatus,
  deliveryShipmentStatusIconByValue,
} from '@fdha/common-utils';
import { useTheme } from '@mui/material';

import { Icon, IconProps } from '../Icon/Icon';

export interface ShipmentStatusIconProps
  extends Omit<IconProps, 'name' | 'fill'> {
  status: DeliveryShipmentStatus;
}

export const ShipmentStatusIcon: React.FC<ShipmentStatusIconProps> = ({
  status,
  ...iconProps
}) => {
  const theme = useTheme();

  const iconFill = useMemo(() => {
    switch (status) {
      case DeliveryShipmentStatus.BeingPrepared:
      case DeliveryShipmentStatus.Transit:
        return theme.palette.info.main;
      case DeliveryShipmentStatus.Delivered:
        return theme.palette.success.main;
      case DeliveryShipmentStatus.Failure:
      case DeliveryShipmentStatus.Returned:
      case DeliveryShipmentStatus.Unknown:
        return theme.palette.text.primary;
    }
  }, [
    status,
    theme.palette.info.main,
    theme.palette.success.main,
    theme.palette.text.primary,
  ]);

  return (
    <Icon
      name={deliveryShipmentStatusIconByValue[status]}
      fill={iconFill}
      {...iconProps}
    />
  );
};
