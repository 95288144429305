import React, { useEffect } from 'react';
import { ResetPassword } from '@fdha/web-auth';
import { Routes, Route, Outlet } from 'react-router-dom';
import { useActivityDetector } from '@fdha/common-hooks';
import { Box } from '@mui/material';

import { Create, Home, Patient, Profile } from '../pages';
import MenuTab from '../pages/create/tabs/menu/MenuTab';
import DietPlanTab from '../pages/create/tabs/dietPlan/DietPlanTab';
import MenuPreview from '../pages/create/tabs/menu/MenuPreview';
import DietPlanPreview from '../pages/create/tabs/dietPlan/DietPlanPreview';
import AddDietPlan from '../pages/create/tabs/dietPlan/AddDietPlan';
import EditDietPlan from '../pages/create/tabs/dietPlan/EditDietPlan';

const Layout = () => {
  return (
    <Box minHeight="100%" display="flex" padding="40px">
      <Outlet />
    </Box>
  );
};

export const Private = () => {
  const { init: initActivityDetector } = useActivityDetector();

  useEffect(() => {
    initActivityDetector();
  }, [initActivityDetector]);

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="create" element={<Create />}>
          <Route index element={<MenuTab />} />
          <Route path="diet-plan" element={<DietPlanTab />} />
        </Route>
        <Route path="create/menu/:menuId" element={<MenuPreview />} />
        <Route
          path="create/diet-plan/:dietPlanId"
          element={<DietPlanPreview />}
        />
        <Route path="create/diet-plan/add" element={<AddDietPlan />} />
        <Route
          path="create/diet-plan/edit/:dietPlanId"
          element={<EditDietPlan />}
        />
        <Route path="profile" element={<Profile />} />
        <Route path="patient/:patientId" element={<Patient />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Route>
    </Routes>
  );
};
