import React from 'react';
import { createRoot } from 'react-dom/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Amplify } from 'aws-amplify';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { theme, DialogProvider, SnackbarProvider } from '@fdha/web-ui-library';
import fdhaConfig from '@fdha/aws-config-foodops';
import { BrowserRouter } from 'react-router-dom';
import { TranslationProvider } from '@fdha/common-hooks';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { apolloClientConfig } from './config';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: fdhaConfig.cognito.foodOpsUserPoolId,
      userPoolClientId: fdhaConfig.cognito.foodOpsUserPoolClientId,
    },
  },
});

const apolloClient = new ApolloClient(apolloClientConfig);

const container = document.getElementById('root');
if (!container) throw new Error('Root element not found');

const root = createRoot(container);

root.render(
  <TranslationProvider>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={apolloClient}>
        <SnackbarProvider>
          <DialogProvider>
            <CssBaseline />
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </DialogProvider>
        </SnackbarProvider>
      </ApolloProvider>
    </ThemeProvider>
  </TranslationProvider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
