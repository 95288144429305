import { LabelBox, Loader } from '@fdha/web-ui-library';
import { Divider, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import React, { useMemo } from 'react';
import { useGetDietPlanQuery } from '@fdha/graphql-api-foodops';

import { BasePage, PreviewPaper } from '../../../../components';

import { Week, buildWeeks, convertDietPlanToUIFormat } from './util';

const DietPlanPreview = () => {
  const { dietPlanId } = useParams();
  const { data, loading } = useGetDietPlanQuery({
    variables: { id: dietPlanId || '' },
    fetchPolicy: 'cache-and-network',
  });

  const dietPlan = data?.dietPlanByIdV2;

  const dietPlanFormatted = useMemo(() => {
    if (!dietPlan) {
      return;
    }

    return convertDietPlanToUIFormat(dietPlan);
  }, [dietPlan]);

  const renderWeeks = () => {
    if (dietPlanFormatted) {
      return buildWeeks(dietPlanFormatted.menusBySequenceNumber).map(
        (week: Week, index: number) => (
          <Grid container key={index}>
            <LabelBox
              size={6}
              label={week.firstDelivery.label}
              value={week.firstDelivery.menu?.name}
            />
            <LabelBox
              size={6}
              label={week.secondDelivery.label}
              value={week.secondDelivery.menu?.name}
            />
          </Grid>
        )
      );
    }
  };

  return (
    <BasePage>
      <BasePage.BackButton to="/create/diet-plan" />
      {loading ? (
        <Loader />
      ) : (
        <>
          <PreviewPaper>
            <Typography padding={2} fontWeight="500">
              Diet Plan Name
            </Typography>
            <Divider />
            <Typography padding={2}>{dietPlanFormatted?.name}</Typography>
          </PreviewPaper>
          <PreviewPaper sx={{ padding: 3 }}>{renderWeeks()}</PreviewPaper>
        </>
      )}
    </BasePage>
  );
};

export default DietPlanPreview;
