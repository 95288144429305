import {
  DeliveryCard,
  Loader,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import { Box, Paper, Typography } from '@mui/material';
import { GraphQLError } from 'graphql';
import React, { FC } from 'react';
import {
  DeliveryV2,
  useCancelDeliveryMutation,
  useListDeliveriesQuery,
  useReactivateDeliveryMutation,
} from '@fdha/graphql-api-foodops';
import { getNextDay, parseBackendError } from '@fdha/common-utils';

export interface DeliveryInformationProps {
  patientId?: string;
}

const DeliveryInformation: FC<DeliveryInformationProps> = ({ patientId }) => {
  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const [cancelDelivery] = useCancelDeliveryMutation();
  const [reactivateDelivery] = useReactivateDeliveryMutation();

  const { data, loading, refetch } = useListDeliveriesQuery({
    variables: {
      date: getNextDay(),
      patientId: patientId || '',
    },
    fetchPolicy: 'cache-and-network',
  });
  const deliveries = data?.deliveriesV2;

  const handleCancelDelivery = async (deliveryId: string) => {
    try {
      await cancelDelivery({ variables: { deliveryId } });
      refetch();
      showSnackbar({
        message: 'Delivery canceled with success',
        severity: 'success',
      });
    } catch (error: any) {
      console.error(error);

      const message = parseBackendError(error, 'Error to cancel delivery');

      showSnackbar({
        message,
        severity: 'error',
      });
    } finally {
      closeDialog();
    }
  };

  const openCancelDialog = (deliveryId: string) => {
    openDialog({
      title: 'Are you sure you want to cancel this delivery?',
      content: null,
      confirmButtonLabel: 'Yes, continue',
      cancelButtonLabel: 'Cancel',
      handleConfirm: async () => handleCancelDelivery(deliveryId),
    });
  };

  const handleReactivateDelivery = async (deliveryId: string) => {
    try {
      await reactivateDelivery({ variables: { deliveryId } });
      refetch();
      showSnackbar({
        message: 'Delivery reactivated with success',
        severity: 'success',
      });
    } catch (error: any) {
      console.error(error);

      const message = parseBackendError(error, 'Error to reactivate delivery');

      showSnackbar({
        message,
        severity: 'error',
      });
    } finally {
      closeDialog();
    }
  };

  const openReactivateDialog = (deliveryId: string) => {
    openDialog({
      title: 'Are you sure you want to reactivate this delivery?',
      content: null,
      confirmButtonLabel: 'Reactivate',
      cancelButtonLabel: 'Cancel',
      handleConfirm: async () => handleReactivateDelivery(deliveryId),
    });
  };

  const renderEmptyState = () => (
    <Box display="flex" justifyContent="center">
      <Typography variant="body2">No upcoming deliveries.</Typography>
    </Box>
  );

  return (
    <Box>
      <Typography variant="h6">Deliveries</Typography>
      <Paper sx={{ padding: 3, mt: 1 }}>
        {loading ? (
          <Loader />
        ) : !deliveries?.length ? (
          renderEmptyState()
        ) : (
          deliveries?.map((delivery: DeliveryV2) => (
            <DeliveryCard
              deliveryId={delivery.id}
              deliveryDate={delivery.deliveryDate}
              openChanges={delivery.openChanges}
              status={delivery.status}
              isCanceled={delivery.isCanceled}
              isOwner={false}
              onClick={
                delivery.isCanceled
                  ? () => openReactivateDialog(delivery.id)
                  : () => openCancelDialog(delivery.id)
              }
              buttonLabel={
                delivery.isCanceled ? 'Reactivate Delivery' : 'Cancel Delivery'
              }
            />
          ))
        )}
      </Paper>
    </Box>
  );
};

export default DeliveryInformation;
