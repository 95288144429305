"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var config = {
    api: {
        foodOpsApiBaseUrl: 'https://ysyuu83xi9.execute-api.us-east-1.amazonaws.com/prod/foodops/graphql',
        commonApi: 'https://ysyuu83xi9.execute-api.us-east-1.amazonaws.com/prod/common',
        apiKey: 'xablauapikeyxablau123-prod',
        webSocketBaseUrl: 'wss://2assnrw8ed.execute-api.us-east-1.amazonaws.com/prod'
    },
    cloudfront: 'd1c1rgjojpvagl.cloudfront.net',
    cognito: {
        foodOpsUserPoolId: 'us-east-1_jNyYVnvh0',
        foodOpsUserPoolClientId: 'or7ld7ofbeeptbc6hvdbos62g'
    },
    pinpoint: '818a479129954e0fbfdc4487a5b06421',
    region: 'us-east-1',
    secretsManager: {
        mapsApiKey: 'AIzaSyDLP8REBjQ8Goq1vIyX522we4aMnByRU7k'
    }
};
exports.default = config;
