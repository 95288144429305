import { useState } from 'react';

import {
  INITIAL_ROWS_PER_PAGE,
  RowsPerPageOptions,
} from '../components/Table/Table';

export const useTable = (config?: {
  initialRowsPerPage?: RowsPerPageOptions;
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    config?.initialRowsPerPage || INITIAL_ROWS_PER_PAGE
  );

  const changeRowsPerPage = (rows: RowsPerPageOptions) => {
    setRowsPerPage(rows);
  };

  return { page, setPage, rowsPerPage, changeRowsPerPage };
};
